import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalProvider {
  public accessToken: string;
  public practitionerID: string;
  public status: string;
  public practitionerName: string;
  public locationsArr: any;


  public authUrl = environment.baseUrl + 'uaa/login';
  public adminUrl = environment.baseUrl + 'practitioner';
  public patientServiceEndpoint = environment.baseUrl + 'patient/form';

  constructor() {
    this.accessToken = localStorage.getItem('accessToken');
    this.practitionerID = localStorage.getItem('practitionerID');
    this.practitionerName = localStorage.getItem('practitionerName');
    setInterval(() => {
    this.status = localStorage.getItem('status');
   }, 2000);
  }
  setLocalStorage(key, value) {
    localStorage.setItem(key, value);
    this.accessToken = localStorage.getItem('accessToken');
    this.practitionerID = localStorage.getItem('practitionerID');
    this.practitionerName = localStorage.getItem('practitionerName');
  }

}
