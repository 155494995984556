import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Locations } from '../utility/alert.model';
import { GlobalProvider } from '../utility/global.service';
import { TopMenuService } from './top-menu.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  locations: any = [];
  currentLocationDetails: Locations;
  currentPath: string;
  screenWidth: number;
  showSelectPractitioner: boolean;
  listOfPractitioner: any;
  selectedPractitioner: any;
  @Output() changePractitinor = new EventEmitter<any>();
  // @Output() checkPrimayPhone = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  constructor(public nav: TopMenuService, private router: Router, public globalProvider: GlobalProvider) {
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.currentPath = this.router.url;
    this.nav.getPracticeList().subscribe(res => {
      res.data.locations.forEach(element => {
        this.locations.push(element);
      });

      this.currentLocationDetails = res.data.locations[0];
      localStorage.setItem('checkPrimayPhone', res.data.locations[0]?.selectedPhoneNumber);

    });
    this.showSelectPractitioner = localStorage.getItem('role') === 'superAdmin' ? true : false;
    this.nav.getPractitionerList().subscribe(x => {
      this.listOfPractitioner = x.data;
      const list = this.listOfPractitioner.filter(y => y.practitioner_Id === localStorage.getItem('practitionerID'));
      this.selectedPractitioner = list[0];

     });
  }

  getCurrentLocation(location) {
    console.log(location);
    localStorage.setItem('gmbId', null);
    localStorage.setItem('pageId', null);
    localStorage.setItem('locationID', null);
    // localStorage.setItem('practitionerID', location.practitioner_Id);
    this.globalProvider.setLocalStorage('practitionerID', location.practitioner_Id);
    localStorage.setItem('checkPrimayPhone', location.selectedPhoneNumber);
    this.selectedPractitioner = location;
    this.nav.getPracticeList().subscribe(res => {
      res.data.locations.forEach(element => {
        this.locations.push(element);
      });
      this.currentLocationDetails = res.data.locations[0];
      localStorage.setItem('checkPrimayPhone', res?.data?.locations[0]?.selectedPhoneNumber);
      if (res?.data?.locations[0]?.id) {
        this.setIntegrationDetails(res.data.locations[0].id);
      }
      else{
        if (this.showSelectPractitioner){this.changePractitinor.emit(location.practitioner_Id); }
      }
    });
  }
  setIntegrationDetails(locationId) {
    localStorage.setItem('locationID', locationId);
    this.nav.getGmbDetails().subscribe(gmb => {
      if (gmb.status === 'success') {
        gmb.data.agentId = gmb.data.locationLaunchStatus === 'launched' ? gmb.data.agentId : null;
        localStorage.setItem('gmbId', gmb.data.agentId);
      }
      this.nav.getFbPageId(localStorage.getItem('practitionerID'), locationId).subscribe(pageId => {
        if (pageId.status === 'success') {
          localStorage.setItem('pageId', pageId.data);
        }
        this.changePractitinor.emit(localStorage.getItem('practitionerID'));
      }, () => {
        console.log('Error fetching facebook page details.');
      });
    }, () => {
      console.log('Error fetching GMB details.');
    });
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
