import {
  Component,
  OnInit,
  Renderer2,
  RendererFactory2,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { LandingPageService } from './landing-page.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { concatAll } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, AfterViewInit {
  pageId: any = {};
  preview: any;
  previewCss: any;
  private renderer: Renderer2;
  @ViewChild('myIframe') myIframe: ElementRef;
  dyanamicScripts: any;

  googleFonts: any = [];
  funnelId: any;
  getStyle: any;

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    // Your logic when the mouse leaves the browser window
    this.onMouseLeaveBody();
  }

  constructor(
    private http: LandingPageService,
    private ac: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private rendererFactory: RendererFactory2
  ) {
    this.ac.queryParamMap.subscribe((params) => {
      this.pageId = { ...params };
    });
    this.renderer = rendererFactory.createRenderer(null, null);
    this.http.getGoogleFonts().subscribe((x) => {
      const font = x?.items;
      for (let i = 0; i < font.length; i++) {
        this.googleFonts.push({
          value: font[i].family.toString() + ', sans-serif',
          name: font[i].family.toString(),
        });
      }
      if (x.items && x.items.length > 0) {
        x.items.forEach((fontItem: any) => {
          this.loadGoogleFontDynamically(fontItem.family);
        });
      }
    });
  }

  ngOnInit(): void {
    this.http.getTemplate(this.pageId?.params.id).subscribe((res) => {
      console.log(res, 'ressss');
      this.getStyle = res.data.style;
      this.getStyle = JSON.parse(this.getStyle);
      console.log(this.getStyle, 'styl');
      this.funnelId = res.funnelId;
      this.http.getFunnelsById(this.funnelId).subscribe((y) => {
        console.log(y, 'tt');

        this.preview = this.sanitizer.bypassSecurityTrustHtml(
          `${res?.data?.html}`
        );
        res.data.css += `.card{ width: 100% !important;}`;
        const headScript = document.createElement('script');
        headScript.innerHTML = res?.data?.headerCode;
        const funnelHeader = document.createElement('script');
        funnelHeader.innerHTML = y.headerCode;
        document.head.appendChild(headScript);
        document.head.appendChild(funnelHeader);
        const footerScript = document.createElement('script');
        footerScript.innerHTML = res?.data?.footerCode;
        const funnelFooter = document.createElement('script');
        funnelFooter.innerHTML = y.footerCode;
        document.body.appendChild(footerScript);
        document.body.appendChild(funnelFooter);
        const style = document.createElement('STYLE');
        const additionalStyles = `
        <iframe
        src="your_source_url_here"
        style="height: 400px; width: 400px; margin-top: 15px; padding: 20px;"
        frameborder="0"
        scrolling="no"
        ></iframe>
`;

        const combinedStyles = `${res?.data?.css} ${this.getStyle}`;
        let modifiedStyles = combinedStyles.replace(
          /(h[1-6]|p|gjs-row|\.gjs-cell|body|\.gjs-hovered|\.gjs-cell\s\.gjs-hovered|\.gjs-row\.gjs-selected|\.gjs-selected):hover\s*\{[^}]*\}|(\.gjs-cell\s\.gjs-hovered|\.gjs-row\.gjs-selected|\.gjs-selected)\s*\{[^}]*\}/g,
          ''
        );

        modifiedStyles += ` ${additionalStyles}`;
        style.innerText = modifiedStyles;
        console.log(res?.data?.SEO);
        document.getElementById('dy-style').prepend(style);
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'description');
        meta.setAttribute('content', res?.data?.SEO?.description);
        const meta1 = document.createElement('meta');
        meta1.setAttribute('name', 'keywords');
        meta1.setAttribute('content', res?.data?.SEO?.keywords);
        const imageMeta = document.createElement('meta');
        imageMeta.setAttribute('name', 'image');
        imageMeta.setAttribute('content', res?.data?.SEO?.socialImage);
        document.title = res?.data?.SEO?.title;
        document.head.appendChild(meta);
        document.head.appendChild(meta1);
        document.head.appendChild(imageMeta);

        setTimeout(() => {
          this.extractScriptsFromHtml(this.preview);
          document.addEventListener('DOMContentLoaded', function() {
            const dynamicDiv = document.getElementById('myIframe');
            console.log(dynamicDiv);
            dynamicDiv.classList.remove('card');
          });
        }, 1000);
      });
    });

    // this.meta.updateTag({ name: 'description', content: 'New description' });
    // this.meta.updateTag({ property: 'og:title', content: 'New OG title' });
  }

  private loadGoogleFontDynamically(fontFamily: string) {
    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
      / /g,
      '+'
    )}&display=swap`;
    document.head.appendChild(linkElement);
  }

  extractScriptsFromHtml(htmlString: string) {
    const div = this.renderer.createElement('div');
    div.innerHTML = htmlString;

    const scriptElements = div.getElementsByTagName('script');
    for (let i = 0; i < scriptElements.length; i++) {
      console.log(scriptElements[i]);
      const script = scriptElements[i];
      this.dyanamicScripts += script.textContent;
      this.dyanamicScripts = this.dyanamicScripts.replace(
        /items/g,
        'items_' + [i]
      );
    }
    this.dyanamicScripts = this.dyanamicScripts.replace(/undefined/g, '');
    console.log(this.dyanamicScripts);

    eval(this.dyanamicScripts);
  }
  ngAfterViewInit() {
    const iframeElement = this.myIframe.nativeElement;
    const iframeContent = iframeElement.contentWindow;
    alert(iframeContent);

    if (iframeContent) {
      const iframeDiv = iframeContent.document.querySelector('.card');
      if (iframeDiv) {
        iframeDiv.style.backgroundColor = 'red';
      }
    }
  }
  onMouseLeaveBody() {
    // Your logic when the mouse leaves the body
    console.log('Mouse left the browser body!');
    // Add your code here
  }
}
