import { Component, OnInit, ViewChild } from '@angular/core';
import { TemplateEditorService } from './template-editor-service';
import grapesjs from 'grapesjs';
import 'grapesjs-preset-webpage';
import customCodePlugin from 'grapesjs-custom-code';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalComponent } from '../shared/modal/modal.component';
import { ModalConfig } from '../shared/modal/modal.config';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit {
  editor: grapesjs.Editor;
  option: any = [];
  funnelObj: any;
  funnelId: any;
  stepName = '';
  stepId = '';
  seoForm: FormGroup;
  headerScript = '';
  footerScript = '';
  stepHeader = '';
  stepFooter = '';
  styleElement: any;
  googleFonts: any = [];
  assetImages = [];
  showHeaderBtns = true;
  popUpCount: any = 0;
  activeTab = 'header-code';
  selectedAsset: number;
  tags = new FormArray([]);
  public formSettingConfig: ModalConfig = {
    modalTitle: ' ',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };
  public seoSettingConfig: ModalConfig = {
    modalTitle: 'SEO Meta Data',

    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };
  public deleteSetting: ModalConfig = {
    modalTitle: '',

    hideDismissButton: () => {
      return false;
    },
    hideCloseButton: () => {
      return false;
    },
  };
  public TrackingCodeModelSetting: ModalConfig = {
    modalTitle: ' Tracking Code',

    hideDismissButton: () => {
      return false;
    },
    hideCloseButton: () => {
      return false;
    },
  };

  @ViewChild('formSetting') public formSettingDialge: ModalComponent;
  @ViewChild('seoSetting') public seoSettingModel: ModalComponent;
  @ViewChild('deleteDialog') public deleteModel: ModalComponent;
  @ViewChild('trackingCodeModel') public TrackingModelConfig: ModalComponent;
  deviceName: string = 'set-device-desktop';

  constructor(private http: TemplateEditorService, private fb: FormBuilder, private ac: ActivatedRoute, private router: Router) {
    this.ac.queryParamMap.subscribe((params) => {
      this.funnelObj = { ...params };
    });

    this.seoForm = this.fb.group({
      title: [''],
      description: [''],
      keywords: [''],
      author: [''],
      socialImage: [''],
      tags: this.fb.array([this.createTag()]),
    });

    this.http.getGoogleFonts().subscribe((x) => {
      const font = x?.items;

      const excludedFontFamilies = [
        'Buda',
        'Molle',
        'Sunflower',
        'UnifrakturCook',
      ];

      for (const fontFamily of font) {
        const fontFamilyString = fontFamily.family.toString();
        if (!excludedFontFamilies.includes(fontFamilyString)) {
          this.googleFonts.push({
            value: fontFamilyString + ', sans-serif',
            name: fontFamilyString,
          });
        }
      }


      if (x.items && x.items.length > 0) {
        x.items.forEach((fontItem: any) => {
          const fontFamily = fontItem.family.toString();
          if (!excludedFontFamilies.includes(fontFamily)) {
            this.loadGoogleFontDynamically(fontFamily);

          }
        });
      }
    });
    this.http.getForms().subscribe((res) => {
      if (res.success) {
        res.data.forEach((el) => {
          this.option.push({ value: el.link, text: el.name });
        });
      }
    });
  }

  private loadGoogleFontDynamically(fontFamily: string) {
    const frameElement = document.getElementsByClassName('gjs-frame')[0] as
      | HTMLIFrameElement
      | undefined;

    if (frameElement) {
      const innerHead = frameElement.contentWindow.document.head;
      const styleEle = document.createElement('style');
      styleEle.innerHTML = `
      .row.gjs-selected, .row.gjs-hovered{
        outline: 1.5px solid #39b6ed !important;
      }
      .cell.gjs-selected, .cell.gjs-hovered{
        outline:1.5px solid #57ca37 !important;
      }
      .cell .gjs-selected, .cell .gjs-hovered{
        outline: 1.5px solid #ff7402 !important;
      }
      // .cell{
      //  display: flex;
      // flex-direction: column;
      // justify-content: center;
      // }
      .row{
        align-items: stretch !important;
      }
      // .gjs-block-manager {
      // overflow: hidden;
      // }

      #wrapper {
        text-align: center;
      }

      .navbar {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        background-color: #333;
        color: white;
      }

      .brand-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.5rem;
        margin: .5rem;
      }
      .Com-name{
        font-size: 2 rem;
      }


      .nav-img {
        height: 50px !important;
        width: 50px !important;
        margin-right: 10px !important;
      }

      .navbar-links {
        height: 100%;
      }

      .navbar-links ul {
        display: flex;
        margin: 0;
        padding: 0;
      }
      .navbar-links li {
        list-style: none;
      }

      .navbar-links li a {
        display: block;
        text-decoration: none;
        color: white;
        padding: 1rem;
      }

      .navbar-links li:hover {
        background-color: #555;
      }

      .toggle-button {
          position: absolute;
          top: 1.2rem;
          right: 1rem;
          display: none;
          flex-direction: column;
          justify-content: space-between;
          width: 30px;
          height: 21px;
      }

      .toggle-button .bar {
          height: 3px;
          width: 100%;
          background-color: white;
          border-radius: 10px;
      }
      iframe{
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        iframe, img, div[data-gjs-type="video"]{
          width:100% !important;
        }
      .img{
        width:100% !important;
      }
      .cell {
        display: block;
        width: 100%;
        height:auto !important;
      }

      .navbar {
        flex-direction: column;
        align-items: flex-start;
      }

      .cell .gjs-selected{
        outline: 1.5px solid #ff7402 !important;
      }

      .Com-name{
        font-size: 2 rem;
      }
      .nav-img {
        height: 40px !important;
        width: 40px !important;
        margin-right: 10px !important;
      }

      .toggle-button {
        display: flex;
      }

      .navbar-links {
        display: none;
        width: 100%;
      }

      .navbar-links ul {
        width: 100%;
        flex-direction: column;
      }

      .navbar-links ul li {
        text-align: center;
      }

      .navbar-links ul li a {
        padding: .5rem 1rem;
      }

      .navbar-links.active {
        display: flex;
      }
  }`;
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
        / /g,
        '+'
      )}&display=swap`;
      innerHead.appendChild(linkElement);
      innerHead.appendChild(styleEle);
      this.styleElement = styleEle.innerHTML;
    } else {
      console.error('Could not find an element with class gjs-frame');
    }
  }

  ngOnInit(): void {
    this.http.getForms().subscribe((res) => {
      if (res.success) {
        res.data.forEach((el) => {
          this.option.push({ value: el.link, text: el.name });
        });
      }
    });
    this.http.getTemplate(this.funnelObj?.params.id).subscribe((res) => {
      this.funnelId = res.funnelId;

      this.headerScript = res.data?.headerCode;
      this.footerScript = res.data?.footerCode;
      this.http.getFunnelsById(this.funnelId).subscribe((y) => {
        this.stepName = res.stepName;
        this.stepId = res.id;
        this.stepHeader = y.headerCode;
        this.stepFooter = y.footerCode;
        this.editor.setComponents(res.data?.components);
        this.editor.setStyle(`${res.data?.css}`);
        if (res.data && res.data?.SEO) {
          this.seoForm.patchValue(res.data?.SEO);
        }
      });
      this.editor.runCommand('core:canvas-clear');
    });

    this.editor = grapesjs.init({
      container: '#gjs',
      showOffsets: 1,
      storageManager: false,
      fromElement: true,
      width: 'auto',
      allowScripts: 1,
      plugins: [
        'gjs-preset-webpage',
        customCodePlugin,
        'grapesjs-component-countdown',

        this.NavbarPlugin,
      ],
      pluginsOpts: {
        'gjs-preset-webpage': { blocksBasicOpts: { flexGrid: 1 } }
      },
      styleManager: {
        sectors: [
          {
            name: 'Visibility',
            open: false,
            properties: [
              {
                name: 'Show - Hide',
                property: 'display',
                type: 'radio',
                list: [
                  {
                    name: '<span class="fa fa-eye"></span>',
                    value: 'block',
                  },
                  {
                    name: `<span class="fa fa-eye-slash"></span>`,
                    value: 'none',
                  },
                ],
              },
              // {
              //   name: 'Mobile',
              //   property: 'display',
              //   type: 'radio',
              //   list: [
              //     {
              //       name: '<span class="fa fa-eye"></span>',
              //       value: 'block',
              //     },
              //     {
              //       name: `<span class="fa fa-eye-slash"></span>`,
              //       value: 'none',
              //     },
              //   ],
              // },
            ],
          },
          {
            name: 'Margin Options',
            open: false,
            properties: [
              {
                name: 'Margin Top',
                property: 'margin-top',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Margin Bottom',
                property: 'margin-bottom',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
                default: '0px',
              },
              {
                name: 'Margin Left',
                property: 'margin-left',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
                default: '0px',
              },
              {
                name: 'Margin Right',
                property: 'margin-right',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
                default: '0px',
              },
              {
                name: 'Margin Right',
                property: 'margin-left',
                type: 'select',
                list: [
                  { value: 'auto', name: 'Right' },
                  { value: 'default', name: 'left' }
                ]
              },
              {
                name: 'Margin center',
                property: 'margin',
                type: 'select',
                list: [
                  { value: 'auto', name: 'Center' },
                  { value: 'default', name: 'left' }
                ]
              },
            ],
          },
          {
            name: 'Padding Options',
            open: false,
            properties: [
              {
                name: 'Padding Top',
                property: 'padding-top',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Padding Bottom',
                property: 'padding-bottom',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Padding Left',
                property: 'padding-left',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Padding Right',
                property: 'padding-right',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
                default: 0,
              },
            ],
          },
          {
            name: 'Border Radius',
            open: false,
            properties: [
              {
                name: 'All Edges',
                property: 'border-radius',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Border Radius Right Top',
                property: 'border-top-right-radius',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Border Radius Right Bottom',
                property: 'border-bottom-right-radius',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Border Radius Left Top',
                property: 'border-top-left-radius',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
              {
                name: 'Border Radius Left Bottom',
                property: 'border-bottom-left-radius',
                type: 'slider',
                min: 0,
                max: 100,
                units: ['px', '%', 'em', 'rem'],
              },
            ],
          },

        ],
      },
      traitManager: {
        defaults: {},
        traits: [
          {
            type: 'href-next',
            name: 'href',
            label: 'New href',
          },
        ]
      },
      devices: [
        { name: 'Desktop', width: '' },
        { name: 'Mobile', width: '320px' },
      ]
    });
    setTimeout(() => {
      const fontProperty = this.editor.StyleManager.getProperty('typography', 'font-family');
      const fontweight = this.editor.StyleManager.getProperty('typography', 'font-weight');

      if (fontProperty && fontweight) {
        const options = fontweight.get('list');
        const namesToFilter = ['Medium', 'Extra-Light', 'Light', 'Semi-Bold', 'Extra-Bold', 'Ultra-Bold'];
        const remainingOptions = options.filter(option => !namesToFilter.includes(option.name));
        fontweight.set('list', remainingOptions);

        this.editor.StyleManager.render();

        fontProperty.get('list').push(...this.googleFonts);
        this.editor.StyleManager.render();
      }

      const iframes = document.querySelectorAll('iframe');

      iframes.forEach((iframe) => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        const elementInIframe = iframeDocument.querySelector('.modal-wrapper');

        if (
          elementInIframe &&
          elementInIframe.classList.contains('modal-wrapper')
        ) {
        }
      });
      this.getImages();

    }, 2000);

    this.editor.on('load', () => {
      const deviceManager = this.editor.Panels.getPanel('devices-c');
      if (deviceManager) {
        const tabletButton = deviceManager.buttons.get('set-device-tablet');
        if (tabletButton) {
          deviceManager.buttons.remove(tabletButton);
        }
        const desktopButton = deviceManager.buttons.get('set-device-desktop');

        if (desktopButton) {
          desktopButton.set('active', true);
          this.editor.runCommand('set-device-desktop');
        }
      }

    })

    this.editor.on('asset:add', (response) => {
      if (response && response.id) {
        this.assetsGenerator(response.id);
      }
    });
    this.editor.on('asset:remove', (asset, complete) => {
      const self = this;
      if (asset.attributes.id) {
        this.selectedAsset = asset.attributes.id;
        self.deleteModel.open();

      }
    });


    const textElement = ['HeadLine', 'Sub-headline', 'H3', 'H4', 'H5', 'H6'];

    for (let i = 0; i < textElement.length; i++) {
      const headingId = `heading-${i}`;
      const headingContent = i === 0 ? `<h1>Add your Headline</h1>` : i === 1 ? `<h2>Add your Sub-headline</h2>` : `<h${i + 1}>Insert your heading here</h${i + 1}>`;

      this.editor.BlockManager.add(headingId, {
        id: headingId,
        label: textElement[i],
        media: i === 0 ? '<b>H</b>' : i === 1 ? '<b>A</b>' : `<b>H${i + 1}</b>`,
        category: 'Text',
        content: headingContent,
      });
    }

    this.editor.BlockManager.add('Paragraph', {
      label: 'Paragraph',
      media: '<b>P</b>',
      category: 'Text',
      content: `<p>Your Paragraph text goes Lorem ipsum dolor sit amet. here</p>`,
    });
    this.editor.Blocks.getAll().remove([

      'link',
      'quote',
      'map',
      'video',
      'c92',
      'c114',
      'c646',
    ]);

    this.editor.BlockManager.getAll().remove([
      'c84',
      'c80',
      'c98',
      'c108',
      'form',
      'input',
      'textarea',
      'select',
      'label',
      'checkbox',
      'radio',
      'button',
      'c102',
      'c90',
      'c110',
      'c642',
    ]);

    const propertiesToRemove = [
      {
        sector: 'layout',
        properties: [
          'width',
          'max-width',
          'height',
          'min-height',
          'margin',
          'padding',
        ],
      },
      {
        sector: 'typography',
        properties: ['font-size', 'letter-spacing', 'line-height'],
      },
      {
        sector: 'decorations',
        properties: ['border-radius', 'border-radius-c'],
      },
    ];
    propertiesToRemove.forEach(({ sector, properties }) => {
      properties.forEach((property) => {
        this.editor.StyleManager.removeProperty(sector, property);
      });
    });

    this.editor.StyleManager.addProperty('Typography', {
      name: 'Vertical Align',
      property: 'vertical-align',
      type: 'select',
      default: 'auto',
      list: [{
        value: 'auto',
        name: 'auto'
      }, {
        value: 'top !important',
        name: 'top'
      },
      {
        value: 'middle !important',
        name: 'middle'
      },
      {
        value: 'bottom !important',
        name: 'bottom'
      }
      ]
    });
    this.editor.StyleManager.removeSector('layout');
    this.editor.StyleManager.removeSector('extra');
    this.editor.StyleManager.removeSector('general');
    this.editor.StyleManager.removeSector('typography');

    this.editor.Blocks.add('Form', {
      label: 'Forms',
      category: 'Forms',
      resizable: true,

      media: ` <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path class="gjs-block-svg-path" d="M22,5.5 C22,5.2 21.5,5 20.75,5 L3.25,5 C2.5,5 2,5.2 2,5.5 L2,8.5 C2,8.8 2.5,9 3.25,9 L20.75,9 C21.5,9 22,8.8 22,8.5 L22,5.5 Z M21,8 L3,8 L3,6 L21,6 L21,8 Z" fill-rule="nonzero"></path>
      <path class="gjs-block-svg-path" d="M22,10.5 C22,10.2 21.5,10 20.75,10 L3.25,10 C2.5,10 2,10.2 2,10.5 L2,13.5 C2,13.8 2.5,14 3.25,14 L20.75,14 C21.5,14 22,13.8 22,13.5 L22,10.5 Z M21,13 L3,13 L3,11 L21,11 L21,13 Z" fill-rule="nonzero"></path>
      <rect class="gjs-block-svg-path" x="2" y="15" width="10" height="3" rx="0.5"></rect>
    </svg>
    </svg>`,

      render: ({ model, el }) => {
        el.addEventListener('click', () => {
          this.openFormPickerModal();
        });
      },
    });

    this.editor.Blocks.add('Image', {
      activate: true,
      label: 'Image',
      category: 'Basic',
      media: `<svg viewBox="0 0 24 24">
      <path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z" />
      </svg>`,
      content: `<img></img>`,
      draggable: '.row, .column',
    });



    this.editor.Panels.removeButton('options', 'gjs-open-import-webpage');

    this.editor.Panels.getButton('options', 'sw-visibility').set('active', true);

    this.editor.on('run:core:preview', () => {
      this.showHeaderBtns = false;
    });
    this.editor.on('stop:core:preview', () => {
      this.showHeaderBtns = true;
    });
    this.editor.on('change:device', () => {
      const deviceManager = this.editor.Panels.getPanel('devices-c');

      if (deviceManager) {
        const activeButton = deviceManager.buttons.filter(button => button.get('active'))[0];

        if (activeButton) {
          console.log('Device changed to:', activeButton.id);
        } else {
          console.log('No active device selected');
        }
      }
      this.editor.on('device:change', (device) => {
        console.log('Device changed to:', device);
        this.deviceName = device ? device.id : 'No device selected';
        console.log('Selected device:', this.deviceName);
      });
    });
    // this.editor.on('component:styleUpdate', (component) => {
    //   const selectedElement = this.editor.getSelected();
    //   if (selectedElement) {
    //     const updatedStyle = selectedElement.getStyle();
    //     let mediaQuery = '';
    
    //     switch (this.deviceName) {
    //       case 'set-device-desktop':
    //         mediaQuery = '@media (max-width: 2480px)';
    //         break;
    //       case 'set-device-mobile':
    //         mediaQuery = '@media (max-width: 768px)';
    //         break;
    //       default:
    //         console.log('No specific styles for this device');
    //     }
    
    //     selectedElement.addStyle(updatedStyle, { mediaText: mediaQuery });
    //   } else {
    //     console.log('No element selected');
    //   }
    // });
  }

  openFormPickerModal() {
    const modal = this.editor.Modal;
    modal.setTitle('Form Picker');
    const containerDiv = document.createElement('div');
    containerDiv.classList.add('container');
    const loader = document.createElement('div');
    modal.setContent(containerDiv);
    modal.open();

    this.http.getForms().subscribe((res) => {
      if (res.success) {
        const select = document.createElement('select');
        select.classList.add('form-control');

        const options = res.data.map((el) => ({ value: el.link, text: el.name }));

        options.forEach((optionData) => {
          const option = document.createElement('option');
          option.value = optionData.value;
          option.textContent = optionData.text;
          select.appendChild(option);
        });

        containerDiv.appendChild(select);

        const selectElement = containerDiv.querySelector('select');

        selectElement.addEventListener('change', async (event) => {
          let selectedValue = (event.target as HTMLSelectElement).value;
          selectedValue = `${selectedValue}&isLanding=true`;
          const iframeLoader = document.createElement('div');
          iframeLoader.classList.add('loader', 'iframe-loader');
          iframeLoader.innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
          containerDiv.appendChild(iframeLoader);

          const selectedComponent = this.editor.getSelected();
          const frameElement = document.querySelector('.gjs-frame') as
            | HTMLIFrameElement
            | undefined;
          const innerDocument = frameElement.contentWindow.document;
          const iframeHeight = innerDocument.body.scrollHeight;
          if (localStorage.getItem('isModelOpen') === 'true') {
            if (frameElement) {
              const innerHead = frameElement.contentWindow.document.body;

              const iframe = document.createElement('iframe');
              iframe.src = selectedValue;
              if (selectedComponent) {
                const dynamicIframeId = 'dynamicIframe_' + Math.floor(Math.random() * 1000);
                selectedComponent.append(`
                <iframe src=${selectedValue} id="${dynamicIframeId}" frameborder="0" style="min-height:${iframeHeight}px; width:100%;"></iframe>
              `);
              } else {
                console.error('No component selected');
              }
            }
          } else {
            const selectedComp = this.editor.getSelected();
            if (selectedComp) {
              const dynamicIframeId = 'dynamicIframe_' + Math.floor(Math.random() * 1000);
              selectedComp.append(`
                <iframe src=${selectedValue} id="${dynamicIframeId}" frameborder="0" style="min-height:${iframeHeight}px; width:100%;"></iframe>
              `);
            } else {
              console.error('No component selected');
            }
          }
          modal.close();
          iframeLoader.remove();
        });
      } else {
        loader.remove();
        containerDiv.innerHTML = 'Error loading forms.';
      }
    });
  }


  NavbarPlugin(editor) {
    editor.Blocks.add('nav', {
      label: 'Nav Bar',
      category: 'Extra',
      media: `<svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
        <rect class="gjs-block-svg-path" x="15" y="10" width="5" height="1"></rect>
        <rect class="gjs-block-svg-path" x="15" y="13" width="5" height="1"></rect>
        <rect class="gjs-block-svg-path" x="15" y="11.5" width="5" height="1"></rect>
      </svg>`,
      content: `
      <nav class="navbar">
        <div class="brand-title">
         <img src="" alt="" class="nav-img">
         <span class="Com-name"> Company Name</span>
        </div>
        <a href="#" class="toggle-button">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </a>
        <div class="navbar-links">
          <ul>
            <li><a href="">Home</a></li>
            <li><a href="">About</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
      </nav>
      <script>
        function toggleNavbar() {
          const toggleButton = document.querySelector('.navbar .toggle-button');
          const navbarLinks = document.querySelector('.navbar .navbar-links');

          toggleButton.addEventListener('click', () => {
            navbarLinks.classList.toggle('active');
          });
        }
        toggleNavbar();
      </script>
      `,
    });
  }

  captureImage(view) {
    const ele = this.editor.Canvas.getDocument().querySelector('#wrapper');

    this.http.capture(ele).then((dataUrl) => {
      const blob = this.dataURItoBlob(dataUrl);
      const formData = new FormData();
      formData.append('file', blob, 'filename.png');
      formData.append('saveImage', 'false');
      if (blob) {
        this.http.uploadAssetImage(formData).subscribe((x) => {
          console.log('2dataUA', x);
          if (x.message === 'Success') {
            const html = this.editor.getHtml();
            const css = this.editor.getCss();
            const components = this.editor.getComponents();
            const req = {
              funnelId: this.funnelId,
              stepName: this.stepName,
              id: this.funnelObj?.params.id,
              data: {
                components,
                html,
                css,
                SEO: this.seoForm.value,
                headerCode: this.headerScript,
                footerCode: this.footerScript,
                imageDataUrl: x.data.Location,
                style: JSON.stringify(this.styleElement),
              },
            };

            this.http.createTemplate(req).subscribe((response) => {
              if (view === 'preview') {
                window.open(
                  `https://api.dev.hipokratiz.com/webbuilder/v1/preview/${this.funnelObj?.params.id}`,
                  '_blank'
                );
              }
              // if ('seo') {

              // }
            });
          } else {
            console.log('Image upload failed.');
          }
        });
      }
    });
  }
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  createTag() {
    return this.fb.group({
      name: [''],
      content: [''],
    });
  }
  goBack() {
    this.router.navigate(['/funnels']);
  }
  closeDialog() {
    this.seoSettingModel.close();
  }
  saveTemplate(view: string) {
    this.captureImage(view);
    this.seoSettingModel.close();
  }
  seoSubmit() {
    this.saveTemplate('seo');
  }

  openTrackingCodeSettings() {
    this.TrackingModelConfig.open();
  }
  closeTrackingCodeDialog() {

    this.TrackingModelConfig.close();
  }
  saveTrackingCode() {
    this.saveTemplate('');
    this.TrackingModelConfig.close();
  }

  search(activeTab) {
    this.activeTab = activeTab;
  }

  result(activeTab) {
    this.activeTab = activeTab;
  }

  openBackgroundSettings() {
    const wrapperElement =
      this.editor.Canvas.getDocument().querySelector('#wrapper');
    if (wrapperElement) {
      this.editor.select(wrapperElement);
      const decorationsSection = document.querySelector(
        '.gjs-sm-sector__decorations .gjs-sm-properties'
      );
      if (decorationsSection instanceof HTMLElement) {
        decorationsSection.style.display = 'block';
      }
    }
  }

  addPopUpVisiblity() {
    const styleManager = this.editor.StyleManager;
    styleManager.addSector(
      'ShowPopupOn',
      {
        name: 'Show popup on',
        open: true,
        properties: [
          {
            name: 'Display on',
            property: 'display',
            type: 'radio',
            list: [
              {
                name: 'Exit',
                value: 'block',
              },
              {
                name: 'None',
                value: 'none',
              },
            ],
          },
        ],
      },
      { at: 0 }
    );
    this.editor.StyleManager.removeSector('ButtonActions');
  }


  openSeoSettings() {
    this.seoSettingModel.open();
  }

  mypopUp() {
    localStorage.setItem('isModelOpen', 'true');
    localStorage.setItem('popUpCount', this.popUpCount);

    if (localStorage.getItem('popUpCount') === '0') {
      this.editor.addComponents(`
    <div class="modal-wrapper">
      <div class="modal-content">
        <div class="modal-content__header">
          <h3 class="modal-content__title">Modal header</h3>
          <button type="button" class="modal-content__close-button" id="close">&times;</button>
        </div>
        <p>Some text in the Modal..</p>
      </div>
    </div>
    <style>
      .modal-wrapper {
        display: block;
        position: fixed;
        z-index: 100;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0,0,0,0.4);
      }

      .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
      }

      .modal-content__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .modal-content__title {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      .modal-content__close-button {
        color: #aaaaaa;
        padding: 4px;
        font-size: 24px;
        font-weight: bold;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }

      .modal-content__close-button:hover,
      .modal-content__close-button:focus {
        color: #000;
      }
    </style>
    <script>
    let CustomModalWrapper = document.querySelector(".modal-wrapper");
    let closeButton = document.querySelector(".modal-content__close-button");
    closeButton.addEventListener("click", function() {

      CustomModalWrapper.style.display = "none";
      localStorage.setItem('isModelOpen', 'false');
    });
</script>
  `); this.addPopUpVisiblity();
    } else {

      const iframes = document.querySelectorAll('iframe');

      iframes.forEach((iframe) => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        const elementInIframe = iframeDocument.querySelector('.modal-wrapper');

        if (
          elementInIframe &&
          elementInIframe.classList.contains('modal-wrapper')
        ) {
          const closeButton = document.querySelector(
            '.modal-content__close-button'
          );
          if (elementInIframe instanceof HTMLElement) {
            elementInIframe.style.display = 'block';
          }
          closeButton.addEventListener('click', function () {
            if (elementInIframe instanceof HTMLElement) {
              this.editor.StyleManager.removeSector('ShowPopupOn');
              elementInIframe.style.display = 'none';
              localStorage.setItem('isModelOpen', 'false');
            }
          });
        }
      });
    }
    this.popUpCount++;
  }
  addTag() {
    const group = new FormGroup({
      name: new FormControl(''),
      content: new FormControl(''),
    });
    this.getTags.push(group);
  }
  removeTag(index: number) {
    this.getTags.removeAt(index);
  }
  get getTags() {
    return this.seoForm.get('tags') as FormArray;
  }
  onFileChange(event: any) {
    const file = event.target.files[0];
    this.seoForm.patchValue({
      file,
    });
  }

  openTypographySettings() {
    const wrapperElement =
      this.editor.Canvas.getDocument().querySelector('#wrapper');
    const wrapperElement1 =
      this.editor.Panels.getDocument().querySelector('#gjs-pn-panels');
    if (wrapperElement) {
      wrapperElement1.className += ' gjs-sm-open';
    }
    const styleManager = this.editor.StyleManager;
    const fontProperty = styleManager.getProperty('Typography', 'font-family');

    let list = [];
    fontProperty.set('list', list);
    list = [
      fontProperty.addOption({ value: '\'Oswald\', sans-serif', name: 'Oswald' }),
      fontProperty.addOption({
        value: 'Helvetica Neue,Helvetica,Arial,sans-serif',
        name: 'Helvetica',
      }),
      fontProperty.addOption({ value: 'sans-serif', name: 'sans-serif' }),
      fontProperty.addOption({
        value: 'Times New Roman',
        name: 'Times New Roman',
      }),
      fontProperty.addOption({ value: 'Arial Black', name: 'Arial Black' }),
      fontProperty.addOption({ value: 'Tahoma', name: 'Tahoma' }),
      fontProperty.addOption({
        value: 'Verdana, Geneva, sans-serif',
        name: 'Verdana',
      }),
      fontProperty.addOption({
        value: 'Courier New Courier, monospace',
        name: 'Courier New Courier',
      }),
      fontProperty.addOption({ value: '\'Lato\', sans-serif', name: 'Lato' }),
      fontProperty.addOption({
        value: '\'Open Sans\', sans-serif',
        name: 'Open Sans',
      }),
      fontProperty.addOption({
        value: '\'Montserrat\', sans-serif',
        name: 'Montserrat',
      }),
    ];
    fontProperty.set('list', list);
    styleManager.render();

  }
  assetsGenerator(response) {
    const base64DataURL = response;
    if (base64DataURL) {
      const base64Data = base64DataURL.split(',')[1];
      if (base64Data) {
        const binaryDataArray = new Uint8Array(
          Array.from(
            atob(base64Data.replace(/-/g, '+').replace(/_/g, '/')),
            (char) => char.charCodeAt(0)
          )
        );

        const formData = new FormData();
        formData.append(
          'image',
          new Blob([binaryDataArray], { type: 'image/png' }),
          'image.png'
        );
        formData.append('saveImage', 'true');

        this.editor.AssetManager.remove(response);

        this.http.uploadAssetImage(formData).subscribe((x) => {
          console.log('1uploadsuccess', x);
          if (x.message === 'Success') {
            console.log('Image uploaded');
            this.getImages();
          } else {
            console.log('Image upload failed.');
          }
        });
      }
    }
  }
  getImages() {
    this.assetImages = [];
    this.http.getImages().subscribe((images) => {
      this.assetImages = images.data
        ?.map((element) => ({
          type: 'image',
          src: element.url,
          height: 350,
          width: 250,
          name: element.name,
          id: element.id,
          updatedAt: new Date(element.updatedAt),
        }))
        .sort((a, b) => b.updatedAt - a.updatedAt);
      this.editor.AssetManager.add(this.assetImages);
    });
  }
  deleteAsset() {
    this.http.deleteAssetImage(this.selectedAsset).subscribe((x) => {
      console.log('deleteimage', x);
      if (x.message === 'success') {
        console.log('Image deleted');
        this.deleteModel.close();
      } else {
        console.log('Remove image process is failed.');
      }
    });
  }
  closeDeleteDialog() {
    this.getImages();
    this.deleteModel.close();
  }

}
