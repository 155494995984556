import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'hipokratiz-practitioner-fe';

  constructor(private router: Router){}

  ngOnInit(): void{
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        const token = localStorage.getItem('accessToken');
        if (token === undefined) {
            this.router.navigate(['login']);
        }
      }
    }, false);
  }
  // @HostListener("window:beforeunload",["$event"])
  // clearLocalStorage(event){
  //     localStorage.clear();
  // }
}
