import { Component, Input, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../utility/alert.service';
import { GlobalProvider } from '../utility/global.service';
import { SidemenuService } from './sidemenu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() call: any;
  tootTipText = 'Please complete the ongoing call';
  fbPageId = localStorage.getItem('pageId');
  geturl = environment.baseUrl;

  @ViewChild('sb-sidenav', { static: true }) sidenav!: Element;
  isExpanded = false;

  constructor(
    public menu: SidemenuService,
    public globalService: GlobalProvider,
    private router: Router,
    private alert: AlertService,
    private renderer: Renderer2
  ) { }
  ngOnInit(): void {
    // console.log(this.geturl, 'url');
}
expandSlider() {
    this.isExpanded = !this.isExpanded;
}
  getNavigated(url){
    if (this.globalService.status === 'ONBOARDED'){
      this.alert.error('Please add atleast one location to view ' + url.replace(/[^a-zA-Z ]/g, ' ') + ' page.');
    }else{
      this.router.navigate([url]);
    }
  }
}
