<main>
    <div class="h-100">
        <div class="row h-100">
            <div class="col-md-12  text-center login-col-padding">
                <div class="d-flex logo">
                    <div class=" text-left">
                        <span>Hipokratiz</span>
                    </div>
                </div>
                <div class="text-center m-3" *ngIf="authError">
                    <p class="alert alert-danger">{{authError}}</p>
                </div>
                <div class="text-center m-3" *ngIf="authSuccess">
                    <p class="alert alert-success font-small-2">{{authSuccess}}
                        <a routerLink="/login" class="green-text">Click here to login</a>
                    </p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-8 col-9 card m-auto shadow p-md-5 p-4" *ngIf="!authSuccess">
                    <h3 class="mb-4 font-weight-bold text-center">{{title}}</h3>
                    <form class="login-form" [formGroup]="passwordSettingForm">
                        <ng-container *ngIf="changePasswordId === null">
                            <div class="group  mb-2">
                                <label class="small mb-1" for="inputOldPassword">Old Password</label>
                                <input type="password" class="form-control" formControlName="oldPassword" name="oldPassword" id="inputOldPassword" />
                                <div *ngIf="submitted && passwordForm.oldPassword.errors">
                                    <div *ngIf="passwordForm.oldPassword.errors.required" class="text-danger">This field is required.</div>
                                </div>
                            </div>
                            <div class="group  mb-2">
                                <label class="small mb-1" for="inputEmail">Email</label>
                                <input type="text" class="form-control" formControlName="email" name="email" id="inputEmail" />
                                <div *ngIf="submitted && passwordForm.email.errors">
                                    <div *ngIf="passwordForm.email.errors.required" class="text-danger">This field is required.
                                    </div>
                                    <div *ngIf="passwordForm.email.errors.pattern" class="text-danger">Please enter a valid email address.</div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="group  mb-2">
                            <label class="small mb-1" for="inputPassword">New Password</label>
                            <div class="input-group">
                                <input [type]="newFieldTextType ? 'text' : 'password'" class="form-control" formControlName="newPassword" name="newPassword" id="inputPassword" />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <img height="19px" width="19px"
                                            [src]=" newFieldTextType ? './assets/img/eye show-min.png'  : './assets/img/eye hide-min.png' "
                                            (click)="toggleFieldTextType('new') " alt="icon-img">
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="submitted && passwordForm.newPassword.errors">
                                <div *ngIf="passwordForm.newPassword.errors?.required" class="text-danger">This field is required.
                                </div>
                            </div>
                            <div *ngIf="submitted && passwordForm.newPassword.errors">
                                <div *ngIf="passwordForm.newPassword.errors?.noWhiteSpace" class="text-danger">Whitespace is not allowed</div>
                            </div>
                            <div *ngIf="submitted && passwordForm.newPassword.errors && !passwordForm.newPassword.errors?.noWhiteSpace">
                                <div *ngIf="passwordForm.newPassword.errors?.minlength" class="text-danger">Password must be at least 8 characters long.</div>
                            </div>

                        </div>
                        <div class="group  mb-2">
                            <label class="small mb-1" for="confirmPassword">Confirm Password</label>
                            <div class="input-group">
                                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" formControlName="confirmPassword" name="confirmPassword" id="confirmPassword" />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <img height="19px" width="19px"
                                            [src]=" fieldTextType ? './assets/img/eye show-min.png'  : './assets/img/eye hide-min.png' "
                                            (click)="toggleFieldTextType('confirm')" alt="icon-img">
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="submitted && passwordForm.confirmPassword.errors">
                                <div *ngIf="passwordForm.confirmPassword.errors.required" class="text-danger">This field is required.</div>
                                <div *ngIf="passwordForm.confirmPassword.errors.confirmedValidator" class="text-danger">
                                    Password and Confirm Password must be match.</div>
                            </div>
                        </div>
                        <div class="text-center  mt-4 mb-0">
                            <button (click)="onSubmit()" class="btn btn-blue w-100">{{title}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>