import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertComponent } from './alert/alert.component';
import { LoaderInterceptor } from './utility/loader.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConversationComponent } from './conversations/conversation/conversation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxMaskModule} from 'ngx-mask';
import { JwtModule } from '@auth0/angular-jwt';
import { MessageTimePipe } from './message-time.pipe';
import { ActivityComponent } from './conversations/activity/activity.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ListFilterPipe } from './conversations/recordings/list-filter.pipe';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { OrderModule } from 'ngx-order-pipe';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { UserFormComponent } from './from-builder/user-form/user-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LeadActivityComponent } from './conversations/lead-activity/lead-activity.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';


import {Ng2TelInputModule} from 'ng2-tel-input';
import {NgxCopyPasteModule} from 'ngx-copypaste';
// import { NgBootstrapNestedSelectModule } from 'ng-bootstrap-nested-select';
import { ActivitySectionComponent } from './conversations/activity-section/activity-section.component';
import { ChatBoxComponent } from './conversations/chat-box/chat-box.component';
import { TopMenuModule } from './top-menu/top-menu.module';
import { SideMenuModule } from './side-menu/side-menu.module';
import { ModalModule } from './shared/modal/modal.module';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { FunnelsComponent } from './funnels/funnels.component';
import { FunnelStepsComponent } from './funnel-steps/funnel-steps.component';
import { EscapeHtmlPipe } from './funnel-steps/convert-html.pipe';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
//import { EmailBuilderComponent } from './reputations/email-builder/email-builder.component';
//import { EmailEditorComponent } from './reputations/email-editor/email-editor.component';
//import { ReviewsQrComponent } from './reputations/reviews-qr/reviews-qr.component';

//import { SmsReviewComponent } from './reputations/sms-review/sms-review.component';
//import { Review1Component } from './reviews/review/review1/review1.component';

export function tokenGetter(): any {
  return localStorage.getItem('access_token');

}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    LoaderComponent,
    ConversationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MessageTimePipe,
    ActivityComponent,
    ListFilterPipe,
    LeadActivityComponent,
   ChatBoxComponent,
   ActivitySectionComponent,
   TemplateEditorComponent,
   FunnelsComponent,
   FunnelStepsComponent,
   EscapeHtmlPipe,
   LandingPageComponent,
  // EmailBuilderComponent,
  // EmailEditorComponent,
  //  ReviewsQrComponent,

  // SmsReviewComponent,
  // Review1Component

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    TopMenuModule,
    SideMenuModule,
    ModalModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragDropModule,
    DragulaModule,
    OrderModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2TelInputModule ,
    NgxCopyPasteModule,
    AutocompleteLibModule,
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [],
        disallowedRoutes: [],
      },
    }),
    RecaptchaModule.forRoot({
      siteKey: '6Lc7beIfAAAAAAs8uBJ7m8MlfOJReukZ9lRqdtt-',
  }),
  // NgBootstrapNestedSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor, multi: true },
      DragulaService,
      { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
