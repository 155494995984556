import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FunnelsService } from './funnels.service';
import { ModalComponent } from '../shared/modal/modal.component';
import { ModalConfig } from '../shared/modal/modal.config';
import { SidemenuService } from '../side-menu/sidemenu.service';
import { TopMenuService } from '../top-menu/top-menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-funnels',
  templateUrl: './funnels.component.html',
  styleUrls: ['./funnels.component.scss'],
  providers: [SidemenuService,
    TopMenuService, ]
})

export class FunnelsComponent implements OnInit {
  showStepPage: any;
  newArray: any = [];
  funnelName = '';
  domainLink = '';
  selectedFunnelId = '';
  stepName: any;
  showError: boolean;
  funnelsList: any = [];
  public menu: SidemenuService;
  public nav: TopMenuService;
  domainValue: string = environment.domainValue;


  @ViewChild('funnelDetails') private funnelDetailsDialog: ModalComponent;
  @ViewChild('settingDetails') private settingDetailsDialog: ModalComponent;

  public funnelDetailsConfig: ModalConfig = {
    modalTitle: 'Create New Funnel',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };

  public settingDetailsConfig: ModalConfig = {
    modalTitle: 'Domain Settings',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };

  constructor(private router: Router, private http: FunnelsService, private injector: Injector) {
    this.menu = this.injector.get(SidemenuService);
    this.nav = this.injector.get(TopMenuService);
  }

  ngOnInit(): void {
    this.nav.hide();
    this.menu.show();
    this.getAllFunnels();
  }
  saveFunnel() {
    this.newArray.push({ name: this.funnelName, action: 'draft', update: new Date(), items: '3steps' });
  }
  steppage(val: any) {
    this.router.navigate(['/funnel-steps'],  { queryParams: { id: val.id} });
  }
  openDialog() {
    this.funnelDetailsDialog.open();
  }
  settingDialog(val) {
    this.domainLink = val.domain;
    this.selectedFunnelId = val.id;
    this.settingDetailsDialog.open();
  }
  closeFunnelDetailsDialog() {
    this.funnelDetailsDialog.close();
  }
  closeSettingDetailsDialog() {
    this.settingDetailsDialog.close();
  }
  createFunnel() {
    if (this.funnelName !== '') {
      this.showError = false;
      const req = {
        practitionerId: localStorage.getItem('practitionerID'),
        funnelName: this.funnelName
      };
      this.http.createFnnel(req).subscribe(res => {
        console.log(res);
        console.log('Created');
        this.funnelName = '';
        this.getAllFunnels();
        this.funnelDetailsDialog.close();
        this.router.navigate(['/funnel-steps'],  { queryParams: { id: res.id} });
      });
    } else {
      this.showError = true;
    }
  }

  createSettingFunnel() {
    if (this.domainLink !== '') {
      this.showError = false;
      const req = {
        domain: this.domainLink
      };

      this.http.createDomainName(req, this.selectedFunnelId).subscribe(res => {
        console.log(res);
        console.log('Created');
        this.domainLink = '';
        this.getAllFunnels();
        this.settingDetailsDialog.close();
      });
    } else {
      this.showError = true;
    }
  }

  deleteFunnel(funnel: any) {
    this.http.deleteFnnel(funnel).subscribe(res => {
      console.log('Deleted');
      this.getAllFunnels();
    });
  }

  getAllFunnels() {
    this.http.getAllFunnels().subscribe(funnels => {
      funnels.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.funnelsList = funnels;
    });


  }
  copyInputMessage(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
