<div class="bg-shadow"></div>
<nav class="sb-topnav navbar navbar-expand">
    <div class="dropdown location-dropdown">
        <ng-container *ngIf="showSelectPractitioner">
            <button class="dropbtn"> {{selectedPractitioner?.practitioner_firstName}} {{selectedPractitioner?.practitioner_lastName}} -- {{selectedPractitioner?.practiceName}} - {{selectedPractitioner?.city}},
                {{selectedPractitioner?.country |uppercase}}<span class="pull-right">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                </button>
            <div class="dropdown-content" *ngIf="showSelectPractitioner">
                <ul class="select-location">
                    <li *ngFor="let location of listOfPractitioner" (click)="getCurrentLocation(location)">
                        <div><span>{{location.practitioner_firstName}} {{location.practitioner_lastName}}</span></div>
                        <div *ngIf="location.practiceName" class="small"><span>{{location.practiceName}} - {{location.city}} ,{{location.country}}</span></div>
                    </li>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="!showSelectPractitioner">
            <button class="dropbtn"> {{selectedPractitioner?.firstName}} {{selectedPractitioner?.lastName}} -- {{selectedPractitioner?.practiceName}} - {{selectedPractitioner?.city}},
            {{selectedPractitioner?.country |uppercase}}
            </button>
        </ng-container>
        
    </div>
   <ul class="navbar-nav ">
        <div class="whole">
            <div class="link fadeInDown text-center fn" [ngClass]="screenWidth >= 1180 ?'show':'hide'"><a href="https://community.hipokratiz.com/" target="blank">Join Hipokratiz Community<a href="https://community.hipokratiz.com/" class="badge badge-success ml-1" target="blank"Y>Join</a></a></div>
    
            <div class="link fadeInDown small" [ngClass]="screenWidth >= 1180 ?'show':'hide'"><a href="https://community.hipokratiz.com/" target="blank">Get tools, systems & training to grow your practice fast</a></div> 
               </div>
        <li> <span class=" mr-1 fadeInDown" [ngClass]="screenWidth <= 1180 ?'show':'hide'"><a href="https://community.hipokratiz.com/" target="blank"><span  [ngClass]="screenWidth >= 750 ?'show':'hide'" class="mx-1">Hipokratiz</span><img src="./assets/img/cm6.png" height="45px" width="45px" alt="cm"><span  [ngClass]="screenWidth >= 750 ?'show':'hide'" class="mx-1">Community</span></a></span></li>
        <li class="user-name" [ngClass]="screenWidth >= 925 ?'show':'hide'">{{globalProvider.practitionerName}}</li>
        <li class="nav-item dropdown  right-menu" ngbDropdown>
            <a class="nav-link dropdown-toggle profile-icon" id="userDropdown" ngbDropdownToggle>
                <img src="./assets/img/profile logout-min.png" class="img-user" alt="logout" height="15px" width="15px">
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu="gfg">
                <h6 class="user-name text-center mb-1" [ngClass]="screenWidth >= 925 ?'hide':'show'" ngbDropdownItem>
                    {{globalProvider.practitionerName}}</h6>
                <ng-container *ngIf="currentPath !== '/profile'">
                    <a class="dropdown-item" routerLink="/profile" ngbDropdownItem> 
                        <img src="./assets/img/profile-min.png" alt="profile" height="11px" width="11px"> Profile Settings</a>
                    <div class="dropdown-divider"></div>
                </ng-container>
                <a class="dropdown-item" (click)="logout()" ngbDropdownItem>
                    <img src="./assets/img/logout-min.png" alt="logout" height="12px" width="12px"> Logout
                </a>
            </div>
        </li>
       
    </ul>
</nav>