import { Injectable } from '@angular/core';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class FunnelStepsService {

    constructor(public http: HttpService) { }

    getStepsById(id: any) {
        return this.http.get(environment.baseUrl + `webbuilder/v1/step/${id}`, true);
    }
    getAllSteps(id: any) {
        return this.http.get(environment.baseUrl + `webbuilder/v1/step/?funnelid=${id}`, true);
    }
    createStep(req: any){
        return this.http.post(environment.baseUrl + 'webbuilder/v1/step/', req, true);
    }
    createPathName(req: any, id: string){
        return this.http.post(environment.baseUrl + `webbuilder/v1/step/${id}/path`, req, true);
    }
    deleteStep(req: any){
        return this.http.del(environment.baseUrl + `webbuilder/v1/step/${req.id}`, true);
    }
    getGoogleFonts() {
        return this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyB4AP78fm59jgCh9w9ZFIuQgW9XcDj2iZo', true);
     }
     getTemplate(id: any) {
        return this.http.get(environment.baseUrl + 'webbuilder/v1/step/' + id, true);
    }
    funnelTrack(body){
return this.http.post(environment.baseUrl + 'webbuilder/v1/funnel/trackingCode/', body, true);
    }
    getFunnelsById(id: any){
        return this.http.get(environment.baseUrl + `webbuilder/v1/funnel/${id}`, true);
    }
}
