import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import { ChangePasswordRequest, PasswordSetRequest } from '../login/login.model';
import { ConfirmedValidator } from '../shared/confirmed.validator';
import { SidemenuService } from '../side-menu/sidemenu.service';
import { NameValidator } from '../shared/space-validator';
import { TopMenuService } from '../top-menu/top-menu.service';
import { GlobalProvider } from '../utility/global.service';
import { RestPasswordService } from './reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [RestPasswordService]
})
export class ResetPasswordComponent implements OnInit {
  passwordSettingForm: FormGroup;
  submitted: boolean;
  changePasswordId: string;
  loading = false;
  authError: string;
  authSuccess: string;
  title: string;
  fieldTextType: boolean;
  newFieldTextType: boolean;
  showError: boolean;

  constructor(private formBuilder: FormBuilder, private global: GlobalProvider,
              public menu: SidemenuService, public nav: TopMenuService,
              private activatedroute: ActivatedRoute, private rpService: RestPasswordService) { }

  ngOnInit(): void {
    this.changePasswordId = this.activatedroute.snapshot.paramMap.get('changePasswordId');
    if (this.changePasswordId){
      this.title = 'Create New Password';
    }else{this.title = 'Change Password'; }
    this.passwordSettingForm = this.formBuilder.group({
      oldPassword: [''],
      email: [''],
      newPassword: ['', [Validators.required, Validators.minLength(8), NameValidator.noWhiteSpace]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: ConfirmedValidator('newPassword', 'confirmPassword')
    });
    if (this.changePasswordId === null) {
      this.passwordForm.oldPassword.setValidators([Validators.required]);
      this.passwordForm.oldPassword.updateValueAndValidity();
      this.passwordForm.email.setValidators([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,3}$')]);
      this.passwordForm.email.updateValueAndValidity();
    }
    this.nav.hide();
    this.menu.hide();
  }
  get passwordForm() { return this.passwordSettingForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.showError = false;
    if (this.passwordSettingForm.valid) {
      if (this.changePasswordId) {
        const passwordRequest: PasswordSetRequest = {
          password: this.passwordSettingForm.controls.newPassword.value,
          changePasswordId: this.changePasswordId.trim()
        };
        this.rpService.passwordSet(passwordRequest)
          .subscribe((data) => {
            if (data.status === 'success') {
              this.authSuccess = 'Success! Your password has been set.';
            }
          },
            error => {
              this.showError = true;
              this.authError = 'Unable to reset the password, please try again later.';
              this.loading = false;
            });
      }else{
        const req: ChangePasswordRequest = {
          currentPassword : this.passwordSettingForm.controls.oldPassword.value,
          email: this.passwordSettingForm.controls.email.value,
          password: this.passwordSettingForm.controls.newPassword.value
        };
        this.rpService.changePassword(req).subscribe(res => {
          if (res.status === 'success') {
               console.log('success');
           }
        },
          error => {
            this.showError = true;
            this.authError = error;
            this.loading = false;
          });
      }

    }
   }
toggleFieldTextType(val) {
  if (val === 'new'){
    this.newFieldTextType = !this.newFieldTextType;
  }else{
    this.fieldTextType = !this.fieldTextType;
  }
}
}


