import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopMenuComponent } from './top-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgBootstrapNestedSelectModule } from 'ng-bootstrap-nested-select';



@NgModule({
  declarations: [TopMenuComponent],
  imports: [
    CommonModule,
    NgbModule,
    // NgBootstrapNestedSelectModule
  ],
  exports: [TopMenuComponent],
  bootstrap: [TopMenuComponent]
})
export class TopMenuModule { }
