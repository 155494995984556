import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalComponent } from '../../shared/modal/modal.component';
import { ModalConfig } from '../../shared/modal/modal.config';
import { SidemenuService } from '../../side-menu/sidemenu.service';
import { TopMenuService } from '../../top-menu/top-menu.service';
import { AlertService } from '../../utility/alert.service';
import { LoaderService } from '../../utility/loader.service';
import { ChatService } from './chat-service';
import { Conversation } from './conversation.model';
import { SmtpServiceService } from '../../smtp/smtp/smtp-service.service';
import { WebsocketService } from './ws-service';
import { direction } from 'html2canvas/dist/types/css/property-descriptors/direction';

const helper = new JwtHelperService();

declare const Twilio: any;
@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  providers: [ChatService, WebsocketService],
  queries: {
    inrineChatBox: new ViewChild('inrineChatBox'),
  },
})
export class ConversationComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  selectedChat: Conversation;
  textMessage: string;
  sending: boolean;
  selectedLocationNumber: any;
  error: boolean;
  customerPhone: any;
  questionnaire: any = [];
  screenWidth: number;
  selectChatIndex: any;
  getSelectedChat: any = [];
  newMsgArr: any = [];
  isShowError: boolean;
  newMessageForm: FormGroup;
  isChatLoader: string;
  successText: string;
  showSuccessText: boolean;
  irineChatRev: any = [];
  public newCn: any = [];
  sendMsgError: string;
  emptyMessage: boolean;
  selectedOperator: string;
  stopHideShow: any = false;
  fbLeadForm: any = [];
  activetab = 'search';
  sendEmail: FormGroup;
  iconHide: boolean;
  domainName: any;
  domainUser: any;
  dmErr: boolean;
  practionerId: any;
  reloadChat: boolean;
  sysForm: any;
  fbForm: any;
  device: any;
  practitioner: any;
  page = 1;
  @ViewChild('newMessageModal') private modalComponent: ModalComponent;
  @ViewChild('conversationDialog') private conversationDialog: ModalComponent;
  @ViewChild('tokenExpiredDialog') private tokenExpiredDialog: ModalComponent;
  @ViewChild('displayImage') private displayImage: ModalComponent;
  @ViewChild('quickFilter') private quickFilter: ModalComponent;

  public inrineChatBox!: ElementRef;

  mobileChat: boolean;

  phoneFromOpportunities: string;

  searchTerm: string;
  practitionerPage = 1;
  // currentPractitionerNumber: any;
  selectedDirection: string;
  selectedChannel: string;

  public newMessageDialog: ModalConfig = {
    modalTitle: ' ',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };
  public quickFilterConfig: ModalConfig = {
    modalTitle: ' ',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };


  showChatBox = '';
  lengthOfConversation = 0;
  practitionerCount: number;
  filterTypes = [
    { type: 'Unread', value: 'unRead' },
    { type: 'Starred', value: 'starred' },
    { type: 'All', value: 'all' }
  ];
  selectedFilter: string = 'all';

  messageDir = [{ type: 'Inbound', value: 'INBOUND' }, { type: 'Outbound', value: 'OUTBOUND' }]
  messageChannel = [{ type: 'Calls', value: 'Calls' }, { type: 'Email', value: 'Email' },
  { type: 'Facebook', value: 'Facebook' }, { type: 'Instagram', value: 'Instagram' }, { type: 'GBM', value: 'GBM' },
  { type: 'SMS', value: 'SMS' }, { type: 'MMS', value: 'MMS' },
  ]

  filtersForm: FormGroup;
  isCleared: boolean = false;
  filterCount: number = null

  constructor(
    private activatedRoute: ActivatedRoute, private loaderService: LoaderService,
    public menu: SidemenuService,
    public nav: TopMenuService,
    public chatService: ChatService,
    private router: Router,
    private fb: FormBuilder,
    private changeRef: ChangeDetectorRef,
    public alert: AlertService,
    public smtp: SmtpServiceService,
  ) {
    this.getScreenSize();
    this.sendEmail = this.fb.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.phoneFromOpportunities = decodeURIComponent(params.phone);
    });
    this.filtersForm = this.fb.group({
      selectedDirection: ['', Validators.required],
      selectedChannel: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.nav.hide();
    this.menu.show();
    this.newMessageForm = this.fb.group({
      patientName: ['', Validators.required],
      patientPhone: [{ value: '', disabled: true }, Validators.required],
      message: ['', [Validators.required, Validators.minLength(1)]],
    });
    this.chatService.getAllSysForms().subscribe((forms) => {
      this.sysForm = forms.data;
    });

    this.chatService.getAllFbLeads().subscribe((fb) => {
      this.fbForm = fb.data.data;
    });
    this.chatService.onNewMessage().subscribe((msg) => {
      console.log('got new conversation: ' + msg);
      this.chatService.getSearchResults({ pageNumber: this.practitionerPage, type: 'all' }).subscribe(x => {
        if (x.status === 'success') {
          this.practitionerCount = x.data?.length;
          this.newCn = x.data.filter(item => item.patientMail !== '');
          if (typeof msg === 'string' && !msg.includes('Client ID')) {
            this.loadChatData();
          }
        }
      });
    });

    this.smtp.getDetails().subscribe(
      (data) => {
        this.domainName = data?.data?.domainName;
        this.domainUser = data?.data?.prefix;

        if (data.status === 'unverified') {
          this.dmErr = true;
        }
      },
      (error) => {
        this.dmErr = true;
      }
    );
    this.practionerId = localStorage.getItem('practitionerID');
  }
  loadChatData(): void {
    this.chatService.getPracticeList().subscribe(
      (res) => {
        this.selectedLocationNumber = res.data.locations[0].selectedPhoneNumber;
        this.practitioner = res;
        const obj = {
          virtualNumber: this.selectedLocationNumber,
        };
        this.chatService.generateToken(obj).subscribe((data) => {
          this.device = Twilio.Device.setup(data.token, {
            enableRingingState: true,
          });

        });
      },
      (error) => {
        if (localStorage.getItem('locationID') === '') {
          this.conversationDialog.open();
        }
      }
    );
    if (this.phoneFromOpportunities !== 'undefined') {
      const objIdx = this.newCn.findIndex((x) => x.patientNumber === this.phoneFromOpportunities);
      if (objIdx !== -1) {
        this.newCn.unshift(this.newCn[objIdx], 0, 0);
        this.newCn = [...new Set(this.newCn)];
        this.selectChat(this.newCn[0]);
      } else {
        this.showChatBox = 'Your conversation box is empty.';
      }
    } else {
      this.selectChat(this.newCn[0]);
    }
    this.newCn.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }

  selectChat(value) {
    // this.currentPractitionerNumber = JSON.parse(localStorage.getItem('locationDetails')).leadNotificationPhNo1;
    this.showChatBox = '';
    this.activetab = value.patientNumber || (value?.operator !== 'TWILIO' && value?.operator !== 'MAILGUN') ? 'search' : 'styles';
    this.reloadChat = true;
    this.emptyMessage = false;
    this.textMessage = '';
    this.sendEmail.get('subject').reset();
    this.sendEmail.get('message').reset();
    if (value) {
      value.pageNumber = 1;
      this.getSelectedChat = [];
      this.irineChatRev = [];
      this.fbLeadForm = [];
      this.isChatLoader = 'Loading your conversation...';
      this.selectChatIndex = value.id;
      this.selectedOperator = value.operator;
      this.isShowError = false;
      this.selectedChat = value;
      this.setCustomerPhone(value);
      this.extractQuestionnaireData(value);
      this.getconversations(value);
    }
    this.error = false;
  }
  getconversations(value) {
    this.chatService.getChatDetails(value.userId).subscribe((chat) => {
      this.populateChatData(chat?.data);
    });
    if (value.operator === 'TWILIO' || value.operator === 'MAILGUN') {
      this.chatService.getTwilioMessages(value).subscribe(msg => {
        this.lengthOfConversation = msg.data.length;
        this.getSelectedChat = this.getSelectedChat.concat(msg.data);
        this.reloadChat = false;
        // this.getSelectedChat = Array.from(new Set(chats));
        // console.log(this.getSelectedChat)
      });
    }
    if (value.operator === 'FACEBOOK' || value.operator === 'INSTAGRAM') {
      this.chatService.getMetaMessages(value).subscribe(msg => {
        this.lengthOfConversation = msg.data.length;
        this.getSelectedChat = this.getSelectedChat.concat(msg.data);
        this.reloadChat = false;
      });
    }
    if (value.operator === 'GBM') {
      this.chatService.getGmbMessages(value).subscribe(msg => {
        this.lengthOfConversation = msg.data.length;
        this.getSelectedChat = this.getSelectedChat.concat(msg.data);
        this.reloadChat = false;
      });
    }
  }
  setCustomerPhone(value) {
    if (value.operator === 'GBM') {
      this.customerPhone = value.conversationId;
    } else {
      this.customerPhone = value.patientNumber;
    }
  }
  extractQuestionnaireData(value) {
    this.chatService.getChatDetails(value.userId).subscribe((chat) => {
      const questionnaire = chat.data.filter(
        (obj) => obj.phone === this.customerPhone
      );
      this.questionnaire = questionnaire[0];
      if (this.questionnaire) {
        this.questionnaire.mail = questionnaire[0].mail
          ? questionnaire[0].mail
          : questionnaire[0].email;
      }
    });
  }
  populateChatData(groupedArr) {
    const fullChats = [];
    groupedArr.forEach(element => {
      // if (element.phone === this.customerPhone) {
      fullChats.push(element);
      this.irineChatRev = fullChats.filter((item) => !!item.bot);
      this.fbLeadForm = fullChats.filter((item) => !!item.form_id);
      this.populateFormTypes();
      // }
    });


  }
  populateFormTypes() {
    this.fbLeadForm.forEach((el) => {
      if (el.form_type === 'facebook_leadgen') {
        this.fbForm?.forEach((lead) => {
          if (lead.id === el.form_id) {
            el.form_type = lead.name;
          }
        });
      } else {
        this.sysForm.forEach((form) => {
          if (form.id === el.form_id) {
            el.form_type = form.name;
          }
        });
      }
    });
  }


  private scrollActivityToTop() {
    setTimeout(() => {
      this.inrineChatBox.nativeElement.scrollTo(0, 0);
    }, 100);
  }
  sendMessage(selectedChat, msg) {
    this.isChatLoader = '';
    const body = {
      from: this.selectedLocationNumber,
      to: this.customerPhone,
      message: msg,
    };
    if (
      selectedChat.operator === 'TWILIO' ||
      selectedChat.operator === 'MAILGUN' ||  // Fix: Change 'selectedChat === 'MAILGUN'' to 'selectedChat.operator === 'MAILGUN''
      selectedChat.operator === 'GBM'
    ) {
      if (msg) {
        this.sending = true;
        this.emptyMessage = false;
        if (selectedChat.operator === 'TWILIO') {
          this.sendTwilioMessage(body);
        } else if (selectedChat.operator === 'MAILGUN') {
          this.sendTwilioMessage(body);  // Assuming you have a method called sendMailgunMessage
        } else if (selectedChat.operator === 'GBM') {
          this.sendGBMMessage(msg);
        }
      } else {
        this.emptyMessage = true;
      }
    } else {
      this.sendMessageToPage(selectedChat, msg);
    }
  }

  sendTwilioMessage(body) {
    this.chatService.sendMessage(body).subscribe(
      (res) => {
        this.successText = 'Your message has been sent successfully.';
        this.sending = false;
        this.textMessage = '';
        this.newMessageForm.reset();
        this.newMessageForm.get('patientName').setValue('');
        this.getconversations(this.selectedChat);
      },
      (error) => {
        this.error = true;
        this.sending = false;
        this.successText = 'Something went wrong. Please try again.';
      }
    );
    this.textMessage = '';
  }
  sendGBMMessage(msg) {
    const obj = {
      text: msg,
      conversation_id: this.customerPhone,
      representativeType: 'HUMAN',
      practitionerId: localStorage.getItem('practitionerID'),
    };

    this.chatService.sendMessageTogbm(obj).subscribe((res) => {
      this.error = false;
      for (const key in res.data) {
        if (key === this.selectedChat.conversationId) {
          this.getSelectedChat = res.data[key].reverse();
        }
      }
      this.sending = false;
      this.textMessage = '';
    });
  }
  sendMail(email: string) {
    this.isChatLoader = '';
    this.sending = true;
    const obj = {
      practitionerID: localStorage.getItem('practitionerID'),
      to: email,
      subject: this.sendEmail.get('subject').value,
      message: this.sendEmail.get('message').value,
    };
    this.chatService.postMail(obj).subscribe(
      (data) => {
        if (data.status === 'success') {
          this.sending = false;
        } else {
          this.error = true;
        }
      },
      (error) => {
        this.error = true;
      }
    );
    this.sendEmail.reset();
  }


  newMessage() {
    this.newMsgArr = this.newCn.filter((obj) => obj.operator === 'TWILIO');
    this.error = false;
    this.modalComponent.open();
  }
  closeDialog() {
    this.sending = false;
    this.showSuccessText = false;
    this.newMessageForm.reset();
    this.newMessageForm.get('patientName').setValue('');
    this.customerPhone = this.selectedChat.receiver;
    this.modalComponent.close();
  }
  sendNewMessage(operator) {
    this.showSuccessText = true;
    this.sendMessage({ operator }, this.newMessageForm.get('message').value);
  }

  getCustomerPhone(value) {
    const selectedItem = this.newMsgArr.find((item) => item.id === value);
    this.newMessageForm.get('patientPhone').setValue(selectedItem.patientNumber);
    this.customerPhone = selectedItem.patientNumber;
  }
  errorDialog() {
    this.conversationDialog.close();
    this.router.navigate(['/profile']);
  }
  showChatList(list) {
    this.mobileChat = true;
    this.selectChat(list);
  }
  backToList() {
    this.mobileChat = false;
  }
  ngOnDestroy() {
    // clearInterval(this.clearChat);
  }

  sendMessageToPage(value, msg) {
    this.isChatLoader = '';
    const obj = {
      pageId: localStorage.getItem('pageId'),
      receipientId: value.patientPageId,
      message: msg,
    };
    this.sending = true;
    if (msg) {
      this.emptyMessage = false;
      this.chatService.sendMessageToPage(obj).subscribe(
        (response) => {
          this.handleSendMessageResponse(response, obj);
        },
        (error) => {
          this.error = true;
          this.sending = false;
          this.textMessage = '';
          this.sendMsgError =
            'This message is being sent outside the allowed window.';
        }
      );
    } else {
      this.sending = false;
      this.emptyMessage = true;
    }
  }
  handleSendMessageResponse(response, obj) {
    if (response.status === 'success') {
      this.successText = 'Your message has been sent successfully.';
      this.textMessage = '';
      this.sending = false;
    } else {
      this.enableErrorBlock();
    }
  }
  onKeydown(event) {
    event.preventDefault();
  }
  ngAfterViewChecked(): void {
    this.changeRef.detectChanges();
  }

  enableErrorBlock() {
    this.error = true;
    this.sending = false;
    this.sendMsgError = 'Something went wrong. Please try again.';
  }
  clearSesstionDialog() {
    this.tokenExpiredDialog.close();
    this.router.navigate(['login']);
  }
  selectPractitioner(el) {
    localStorage.setItem('practitionerID', el);
    this.chatService.getPractitionerDetails(el).subscribe((x) => {
      this.selectedLocationNumber = x.data.locations[0]?.selectedPhoneNumber;
      if (x.data.locations.length > 0) {
        this.isShowError = false;
        // this.refreashChat(true);
      } else {
        this.newCn = [];
        this.isShowError = true;
        this.alert.error('No locations available for this practitioner');
      }
    });
  }
  search(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activetab = activeTab;
  }

  styles(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activetab = activeTab;
  }
  option(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activetab = activeTab;
  }
  icon() {
    this.iconHide = true;
  }
  fullIcon() {
    this.iconHide = false;
  }
  navPage() {
    this.router.navigate(['/email']);
  }


  searchContact(value: string): void {
    value = value.trim().toLowerCase();
    const req = {
      word: value,
      type: this.selectedFilter,
      pageNumber: this.practitionerPage,
      direction: this.selectedDirection,
      operator: this.selectedChannel
    }
    if (value.length > 0) {
      this.chatService.getSearchResults(req).subscribe(res => {
        this.newCn = res.data;
        this.practitionerCount = res.data?.length;
        if (this.newCn.length > 0) {
          this.selectChat(this.newCn[0]);
        } else {
          this.showChatBox = 'No conversation selected';
        }
      })
    } else {
      this.chatService.getSearchResults({ pageNumber: this.practitionerPage}).subscribe(x => {
        this.newCn = x.data;
      });
    }
  }
  loadOldPractitioners() {
    this.practitionerPage++;
    this.chatService.getSearchResults({ pageNumber: this.practitionerPage, type: this.selectedFilter }).subscribe(x => {
      if (x.status === 'success') {
        this.practitionerCount = x.data?.length;
        const list = x.data.filter(item => item.patientMail !== '');
        this.newCn = this.newCn.concat(list);
        if (this.newCn.length > 0) {
          this.selectChat(this.newCn[0]);
        } else {
          this.showChatBox = 'No conversation selected';
        }
      }
    });
  }
  selectTypeOfFilter(filter: any) {
    this.practitionerPage = 1;
    this.selectedFilter = filter?.value;
    const req = {
      type: filter?.value,
      pageNumber: this.practitionerPage,
      direction: this.selectedDirection,
      operator: this.selectedChannel
    }
    this.chatService.getSearchResults(req).subscribe(x => {
      if (x.status === 'success') {
        this.practitionerCount = x.data?.length;
        this.newCn = x.data;
        if (this.newCn.length > 0) {
          this.selectChat(this.newCn[0]);
        } else {
          this.showChatBox = 'No conversation selected';
        }
      }
    });
  }
  openQuickFilter() {
    this.quickFilter.open();
  }
  closeFilterDialog() {
    this.filtersForm.reset();
    this.quickFilter.close();
  
  }
  selectedFilterItems() {
    const req = {
      type: this.selectedFilter,
      pageNumber: this.practitionerPage,
      direction: this.selectedDirection,
      operator: this.selectedChannel
    }
    this.chatService.getSearchResults(req).subscribe(res => {
      this.newCn = res.data;
      this.practitionerCount = res.data?.length;
      if (this.newCn.length > 0) {
        this.selectChat(this.newCn[0]);
      } else {
        this.showChatBox = 'No conversation selected';
      }
    })
    this.quickFilter.close();

  }
  selectFilter(type: 'direction' | 'channel', value: string) {
    this.filtersForm.patchValue({ [`selected${type.charAt(0).toUpperCase() + type.slice(1)}`]: value });

    if (type === 'direction') {
      if(this.selectedDirection == value){
        this.selectedDirection = null;
      }else{
        this.selectedDirection = value;
      }
      
    } else if (type === 'channel') {
      if(this.selectedChannel == value){
        this.selectedChannel = null;
      }else{this.selectedChannel = value;}
    }

    this.getcount();
  }
  cleaerFilter() {
    this.filtersForm.reset();
    this.isCleared = true;
    this.selectedDirection = '';
    this.selectedChannel = ''
    this.getcount()
  }

  getcount() {
    if (this.selectedDirection && this.selectedChannel) {
      this.filterCount = 2;
    } else if (!this.selectedDirection && !this.selectedChannel) {
      this.filterCount = null;
    } else {
      this.filterCount = 1;
    }
  }
  updateConversationStatus(){
    const req = {
      type: this.selectedFilter,
      pageNumber: this.practitionerPage,
      direction: this.selectedDirection,
      operator: this.selectedChannel
    }
    this.chatService.getSearchResults(req).subscribe(res => {
      this.newCn = res.data;
      this.practitionerCount = res.data?.length;
      if (this.newCn.length > 0) {
        this.selectChat(this.newCn[0]);
      } else {
        this.showChatBox = 'No conversation selected';
      }
    })
  }
}
