import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConversationComponent } from './conversations/conversation/conversation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './services/auth.guard';
import { FormBuilderDataComponent } from './from-builder/form-builder-data/form-builder-data.component';
import { FromBuilderComponent } from './from-builder/from-builder.component';
import { UserFormComponent } from './from-builder/user-form/user-form.component';
import { WorkFlowComponent } from './work-flow/work-flow.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { FunnelsComponent } from './funnels/funnels.component';
import { FunnelStepsComponent } from './funnel-steps/funnel-steps.component';
import { LandingPageComponent } from './landing-page/landing-page.component';





const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'resendOnboardingEmail', component: ForgotPasswordComponent },
  { path: 'password-setting/:changePasswordId', component: ResetPasswordComponent },
  { path: 'conversations', component: ConversationComponent, canActivate: [AuthGuard] },
  { path: 'funnels', component: FunnelsComponent, canActivate: [AuthGuard] },
  { path: 'site', component: TemplateEditorComponent, canActivate: [AuthGuard] },
  { path: 'funnel-steps', component: FunnelStepsComponent, canActivate: [AuthGuard] },
  { path: 'landing-page', component: LandingPageComponent },
  {
    path: 'email', loadChildren: () => import('./smtp/smtp/smtp.module').then((m) => m.SmtpModule), canActivate: [AuthGuard]
  },
  {
    path: 'opportunities', loadChildren: () => import('./opportunities/opportunities/opportunities.module').
      then((m) => m.OpportunitiesModule), canActivate: [AuthGuard]
  },
  {
    path: 'pipelines', loadChildren: () => import('./opportunities/pipeline/pipeline.module').
      then((m) => m.PipelineModule), canActivate: [AuthGuard]
  },
  {
    path: 'fb-forms', loadChildren: () => import('./fb-field-mapping/fb-field-mapping.module').
      then((m) => m.FbFieldMappingModule), canActivate: [AuthGuard]
  },
  {
    path: 'recordings', loadChildren: () => import('./conversations/recordings/recordings.module').
      then((m) => m.RecordingsModule), canActivate: [AuthGuard]
  },
  {
    path: 'bot-configuration', loadChildren: () => import('./bot-configuration/bot-configuration/bot-configuration.module').
      then((m) => m.BotConfigurationModule), canActivate: [AuthGuard]
  },
  {
    path: 'integrations', loadChildren: () => import('./integration/integration.module').
      then((m) => m.IntegrationModule), canActivate: [AuthGuard]
  },
  {
    path: 'profile', loadChildren: () => import('./location-form/location-form.module').
      then((m) => m.LocationFormModule), canActivate: [AuthGuard]
  },
  {
    path: 'add-custom', loadChildren: () => import('./from-builder/add-custom-field/add-custom-field.module').
      then((m) => m.AddCustomFieldModule), canActivate: [AuthGuard]
  },
  {
    path: 'custom-form', loadChildren: () => import('./from-builder/custom-form/custom-form.module').
      then((m) => m.CustomFormModule), canActivate: [AuthGuard]
  },
  {
    path: 'form-builder', loadChildren: () => import('./from-builder/form-builder-data/form-builder-data.module').
      then((m) => m.FormBuilderDataModule), canActivate: [AuthGuard]
  },
  {
    path: 'new-forms', loadChildren: () => import('./from-builder/form-builder.module').
      then((m) => m.FormBuilderModule)
  },
  {
    path: 'user-form', loadChildren: () => import('./from-builder/user-form/user-form.module').
      then((m) => m.UserFormModule)
  },
  {
    path: 'custom-values', loadChildren: () => import('./from-builder/customvalues/customvalues.module').
      then((m) => m.CustomvaluesModule), canActivate: [AuthGuard]
  },
  {
    path: 'work-flow', loadChildren: () => import('./work-flow/work-flow.module').
      then((m) => m.WorkFlowModule), canActivate: [AuthGuard]
  },

  {
    path: 'review', loadChildren: () => import('./reviews/review-list/review1.module').
      then((m) => m.Review1Module), canActivate: [AuthGuard]
  },

  {
    path: 'reputation', loadChildren: () => import('../../src/app/reputations/reputation/reputation.module').
      then((m) => m.ReputationModule), canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
