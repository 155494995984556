import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ModalConfig } from 'src/app/shared/modal/modal.config';
import { ChatService } from '../conversation/chat-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-activity-section',
  templateUrl: './activity-section.component.html',
  styleUrls: ['./activity-section.component.scss']
})
export class ActivitySectionComponent implements OnInit {
  activeTab = 'irine';
  @Input() selectedChat: any;
  @Input() irineChatRev: any;
  @Input() fbLeadForm: any;
  description: string = '';
  availableNotes: any = [];
  availableTasks: any = [];
  practitionerName = localStorage.getItem('practitionerName');
  submitted: boolean = false;
  selectedItemId: string = '';
  currentDate  = new Date();

  @ViewChild('notesModel') private notesModel: ModalComponent;
  @ViewChild('taskModel') private taskModel: ModalComponent;
  taskFrom !: FormGroup

  public notesModelConfig: ModalConfig = {
    modalTitle: '',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };
  public taskModelConfig: ModalConfig = {
    modalTitle: '',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };

  constructor(private chatService: ChatService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.taskFrom = this.fb.group({
      tasks: ['', Validators.required],
      dueDate: ['', Validators.required],
      isComplete: [false]
    })
  }

  openTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
  }

  openNotesTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
    this.getNotes();
  }
  openTaskTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
    this.getTasks();
  }
  openAddTask(seletedTab) {
    if (seletedTab == 'notes') {
      this.notesModel.open();
    } else {
      this.taskModel.open()
    }

  }
  closeModal(seletedTab) {
    this.submitted = false;
    if (seletedTab == 'task') {
      this.taskModel.close();
    } else { this.notesModel.close(); }

  }
  addTasks() {
    if (this.taskFrom.invalid) {
      this.submitted = true;
      return
    }
    this.submitted = false;
    const req = {
      userId: this.selectedChat?.userId,
      practitionerId: localStorage.getItem('practitionerID'),
      tasks: this.taskFrom.value.tasks,
      dueDate: this.taskFrom.value.dueDate,
    }
    this.chatService.createNote(req, this.activeTab).subscribe(res => {
      if (res.status == 'success') {
        this.taskFrom.reset();
        this.getTasks();
      }
      this.taskModel.close();
    })

  }
  addNotes() {
    if (this.description == '') {
      this.submitted = true;
      return
    }
    this.submitted = false;
    const req = {
      userId: this.selectedChat?.userId,
      practitionerId: localStorage.getItem('practitionerID'),
      notes: this.description
    }
    this.chatService.createNote(req, this.activeTab).subscribe(res => {
      if (res.status == 'success') {
        this.getNotes();
        this.description = '';
      }
      this.notesModel.close();
    })

  }
  updateTasks() {
    if (this.taskFrom.invalid) {
      this.submitted = true;
      return
    }
    this.submitted = false;
    const req = {
      id: this.selectedItemId,
      tasks: this.taskFrom.value.tasks,
      dueDate: this.taskFrom.value.dueDate,
      isComplete: this.taskFrom.value.isComplete,
    }
    this.chatService.updateNote(req, this.activeTab).subscribe(res => {
      if (res.status == 'success') {
        this.taskFrom.reset();
        this.getTasks();
      }
      this.taskModel.close();
    })
  }
  onCheckboxChange(task) {
    task.isComplete = !task.isComplete;

    this.chatService.updateNote(task, this.activeTab).subscribe(res => {
      if (res.status == 'success') {
        this.getTasks();
      }
    })
  }
  updateNotes() {
    if (this.description == '') {
      this.submitted = true;
      return
    }
    this.submitted = false;
    const req = {
      id: this.selectedItemId,
      [this.activeTab === 'tasks' ? 'tasks' : 'notes']: this.description
    }
    this.chatService.updateNote(req, this.activeTab).subscribe(res => {
      if (res.status == 'success') {
        this.getNotes();
        this.getTasks();
        this.description = '';
      }
      this.selectedItemId = '';
      this.notesModel.close();
    })
  }
  GetNotesById(id: string, text: string) {
    this.description = text;
    this.selectedItemId = id;
    this.notesModel.open();
  }
  GetTaskdById(id: string, task) {
    this.selectedItemId = id;
    this.taskFrom.patchValue(task)
    this.taskModel.open();
  }
  getNotes() {
    this.chatService.getNotes(this.selectedChat?.userId, 'notes').subscribe(x => {
      this.availableNotes = x.data;
    })
  }
  getTasks() {
    this.chatService.getNotes(this.selectedChat?.userId, 'tasks').subscribe(x => {
      this.availableTasks = x.data;
    })
  }
  deleteItem(id: string) {
    this.chatService.DeleNotesById(id, this.activeTab).subscribe(x => {
      this.getNotes();
      this.getTasks();
    })
  }

  isPastDue(dueDate): boolean {
    dueDate = new Date(dueDate);
    return this.currentDate > dueDate;
  }
}
