import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SidemenuService } from '../side-menu/sidemenu.service';
import { TopMenuService } from '../top-menu/top-menu.service';
import { GlobalProvider } from '../utility/global.service';
import { ForgotPasswordService } from './forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [ForgotPasswordService]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted: boolean;
  authError: string;
  showError: boolean;
  authSuccess = false;

  constructor(private formBuilder: FormBuilder, private global: GlobalProvider,
              public menu: SidemenuService,
              public nav: TopMenuService, public router: Router, private fpService: ForgotPasswordService) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,3}$')]],
    });
    this.nav.hide();
    this.menu.hide();
  }
  get forgotPassword() { return this.forgotPasswordForm.controls; }
  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.valid) {
      if (this.router.url === '/forgot-password') {
        this.fpService.forgotPassword(this.forgotPasswordForm.value).subscribe(
          (req) => {
            this.response(req);
          },
          (error) => {
            this.handleError();
          }
        );
      } else {
        this.fpService.reSendLink(this.forgotPasswordForm.value).subscribe(
          (req) => {
            this.response(req);
          },
          (error) => {
            this.handleError();
          }
        );
      }
    }
  }
  private response(req: any): void {
    if (req.status === 'success') {
      this.success();
    }
  }
  private handleError() {
    this.authError = 'Something went wrong. Please try again.';

    this.showError = true;

    this.authSuccess = false;
  }
  private success() {
    this.authSuccess = true;

    this.authError = '';
  }
}
