import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageTime',
  pure: true
})
export class MessageTimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      }
      const intervals = {
        year: 31536000,
        month: 2592000,
        w: 604800,
        d: 86400,
        h: 3600,
        m: 60,
        s: 1
      };
      let counter;
      for (const [intervalName, intervalValue] of Object.entries(intervals)) {
        counter = Math.floor(seconds / intervalValue);
        if (counter > 0) {
          return counter + ' ' + intervalName + (counter > 1 ? 's' : '') + ' ago';
        }
      }
      return value;
    }
  }
}