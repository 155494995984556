import { Injectable } from '@angular/core';
import { Observable, Observer, Subject} from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// const CHAT_URL = "ws://localhost:8015/";
// const CHAT_URL = "wss://api.dev.hipokratiz.com/conversation";

export interface Message {
    source: string;
    content: string;
}

@Injectable()
export class WebsocketService {
    private subject: AnonymousSubject<MessageEvent>;
    public messages: Subject<Message>;

    constructor() {
        this.messages = (this.connect(environment.wss).pipe(
            map(
                (response: MessageEvent): Message => {
                    // console.log(response.data);
                    return response.data;
                }
            )
        ) as Subject<Message>);
    }

    public connect(url): AnonymousSubject<MessageEvent> {
        if (!this.subject) {
            this.subject = this.create(url);
            // console.log('Successfully connected: ' + url);
        }
        return this.subject;
    }

    private create(url): AnonymousSubject<MessageEvent> {
        const ws = new WebSocket(url);
        ws.addEventListener('open', () => {
            ws.send(localStorage.getItem('practitionerID' ));
          });
        const observable = new Observable((obs: Observer<MessageEvent>) => {
            ws.onmessage = obs.next.bind(obs);
            ws.onerror = obs.error.bind(obs);
            ws.onclose = obs.complete.bind(obs);
            return ws.close.bind(ws);
        });
        const observer = {
            error: null,
            complete: null,
            next: (data: Object) => {
                console.log('Message sent to websocket: ', data);
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(JSON.stringify(data));
                }
            }
        };
        return new AnonymousSubject<MessageEvent>(observer, observable);
    }
}
