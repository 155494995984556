import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(public global: GlobalProvider,
              public httpSvc: HttpService) { }


  forgotPassword(req): Observable<any> {
    return this.httpSvc.post(environment.baseUrl + 'practitioner/v1/practitioner/forgotpassword', req, false);
  }
  reSendLink(req): Observable<any> {
    return this.httpSvc.post(environment.baseUrl + 'practitioner/v1/practitioner/resendOnboardingEmail', req, false);
  }

}
