import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';
import { LoginRequest } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor( public global: GlobalProvider, public httpSvc: HttpService ) { }


  login(loginReq: LoginRequest): Observable<any> {
    return this.httpSvc.post(this.global.authUrl, loginReq, false);
  }
  getPractitionerStatus(req: any): Observable<any> {
    return this.httpSvc.get(this.global.adminUrl + '/v1/practitioner/mail/' + req, true);
  }
  getPractitionerDetails(id: string) {
    return this.httpSvc.get(environment.practitionerUrl + 'practitioner/' + id, true);
  }
  getFbPageId(practId: string, locationId: any) {
    return this.httpSvc.get(environment.baseUrl + 'tps-meta/v1/connection/page?practitionerId='
      + practId + '&locationId=' + locationId, true);
  }
  getGmbDetails() {
    return this.httpSvc.get(environment.baseUrl + 'tps-google/v1/accountDetails?practitionerId='
      + localStorage.getItem('practitionerID'), true);
  }
  getPractitionerList() {
    return this.httpSvc.get(environment.baseUrl + 'practitioner/v1/practitioner/allDetails', true);
  }
}
