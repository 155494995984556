import { Injectable } from '@angular/core';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';
import * as html2canvas from 'html2canvas';
@Injectable({
    providedIn: 'root'
})
export class TemplateEditorService {

    constructor(public http: HttpService) { }

    createTemplate(reqBody: any) {
        return this.http.patch(environment.baseUrl + 'webbuilder/v1/step', reqBody, true);
    }
    getTemplate(id: any) {
        return this.http.get(environment.baseUrl + 'webbuilder/v1/step/' + id, true);
    }
    getGoogleFonts() {
        return this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyB4AP78fm59jgCh9w9ZFIuQgW9XcDj2iZo', true);
    }
    getForms() {
        return this.http.get(environment.baseUrl + 'marketing/systemForms/' + localStorage.getItem('practitionerID'), true);
    }
    uploadAssetImage(body) {
        const baseUrl = environment.baseUrl;
        const practitionerID = localStorage.getItem('practitionerID');
        const url = `${baseUrl}webbuilder/v1/practitioner/${practitionerID}/asset`;
        return this.http.post(url, body, true);
    }
    deleteAssetImage(body) {
        return this.http.del(environment.baseUrl + 'webbuilder/v1/practitioner/'
            + localStorage.getItem('practitionerID') + '/asset/' + body, true);
    }
    getImages() {
        return this.http.get(environment.baseUrl + 'webbuilder/v1/practitioner/' + localStorage.getItem('practitionerID') + '/asset', true);
    }
    getFunnelsById(id: any) {
        return this.http.get(environment.baseUrl + `webbuilder/v1/funnel/${id}`, true);
    }
    capture(element: HTMLElement): Promise<string> {
        return html2canvas.default(element, { allowTaint: false, useCORS: true, height: 700 }).then((canvas) => {
            return canvas.toDataURL('image/png');
        });
    }
}
