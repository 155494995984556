<div class="card mt-3 profile-card d-none d-lg-block">
  <div class="title d-flex">
    <div class="m-2 col-md-9">
      <ng-container *ngIf="selectedChat?.operator == 'TWILIO' || selectedChat?.operator == 'MAILGUN'">
        <p class="mb-0 title-name font-weight-bold">
          {{ selectedChat?.patientName | titlecase }}
        </p>
        <p class="mb-0 small mt-1" *ngIf="questionnaire">
          <img src="./assets/img/mail-min.png " class="icon-img" alt="mail" />
          {{
          selectedChat.patientMail ? selectedChat?.patientMail : "-"
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img src="./assets/img/tele-min.png " class="icon-img" alt="tele" />
          {{ selectedChat.patientNumber }}
        </p>
      </ng-container>
      <ng-container *ngIf="selectedChat?.operator != 'TWILIO' && selectedChat?.operator != 'MAILGUN'">
        <p class="mb-0 title-name font-weight-bold">
          {{ selectedChat?.patientName | titlecase }}
        </p>
      </ng-container>
    </div>
    <div class="col-md-3 d-flex chat-header-actions">
      <ng-container
        *ngIf="(selectedChat?.operator == 'TWILIO' || selectedChat?.operator == 'MAILGUN') && selectedChat.patientNumber">
        <button [disabled]="!callHideShow" [ngClass]="{ callHideShow: !callHideShow }"
          (click)="makeCall(selectedChat?.patientNumber, selectedChat?.patientName)">
          <span class="call border-0 my-4"><em class="fa fa-phone"></em></span>
        </button>
      </ng-container>
      <button (click)="markAsUnRead(selectedChat)" title="{{selectedChat.isRead ? 'Mark as Unread': 'Mark as read'}}"><i class="fa" [ngClass]="!selectedChat.isRead ? 'fa-envelope-o' : 'fa-envelope-open-o'"
          aria-hidden="true"></i></button>
      <button (click)="markAsStarred(selectedChat)" title="{{selectedChat.starred ? 'UnMark as starred': 'Mark as starred'}}"><i class="fa" [ngClass]="selectedChat.starred ? 'fa-star' : 'fa-star-o'"
          aria-hidden="true"></i></button>
    </div>
    <ng-container *ngIf="stopHideShow === true">
      <div class="dialler-panel" cdkDrag>
        <div class="dialler">
          <p class="duration font-size-11">
            <small>{{ transform(time) }}</small>
          </p>
          <div class="dialler-body">
            <p class="dialler-name">
              <img src="assets/img/profile-1.png" width="50px" alt="profile" />
            </p>
            <div class="dialler-footer">
              <div class="mx-2 text-left" style="width: 40%">
                <p class="m-0 font-weight-bold text-capitalize letter-spacing dialled-patient">
                  {{ selectedPatientName }}
                </p>
                <p class="m-0 letter-spacing font-size-11">
                  <small>{{ phoneNumber }}</small>
                </p>
              </div>
              <div class="dialler-controls">
                <button class="btn call mx-2" (click)="muteCall()">
                  <em class="fa" [ngClass]="
                      isMuted ? 'fa-microphone-slash' : 'fa-microphone'
                    "></em>
                </button>
                <button type="button" class="btn btn-danger mx-2" (click)="Stop()">
                  <img src="assets/img/hang-up.png" width="15px" alt="hang-up" />
                </button>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="card mt-2">
  <div class=" ">
    <!-- <div class="chat-box">
    
    </div> -->
    <div [ngClass]="practitionerNumber !== selectedChat?.patientNumber ? 'conversation-sec': 'conversation-sec-no-tab' "
      #scrollMe [scrollTop]="scrollMe.scrollHeight">
      <div *ngIf="reloadChat">
        <img class="gif" src="./assets/img/loader-1.gif" alt="loader" />
      </div>
      <div class="chat-box" *ngIf="!reloadChat">
        <ng-container *ngIf="lengthOfConversation && lengthOfConversation < 10 ">
          <h6 class="my-3">
            <img src="./assets/img/{{selectedChat.operator}}.png" width="16px" alt="image_icon" /> <span>Conversation
              began</span>
            <small class="text-muted">{{ selectedChat.createdAt | date : "dd/MM/yyyy" }}</small>
          </h6>
        </ng-container>
        <ng-container *ngIf="lengthOfConversation >= 10">
          <p class="my-2">
            <small (click)="loadMoreMessages()" class="pointer text-primary load-more-text">Load older messages</small>
          </p>
        </ng-container>
        <ng-container *ngFor="let message of getSelectedChat | orderBy : 'updatedAt'">
          <div class="message to" [ngClass]="{ from: message.direction === 'INBOUND' }">
            <span *ngIf="message.operator === 'GBM'">{{message.message}}</span>
            <span *ngIf="
                message.type === 'text' ||
                (message.type === 'SMS' || message.type === 'SMS'  && message.mediaType == null)
              ">
              <ng-container *ngIf="message.subject != null">
                <img src="./assets/img/mail-min.png" class="icon-img mail-img mb-1" alt="mail" />
                <span class="mail-sub mx-1">{{ message.subject }}</span><br />
              </ng-container>
              {{ message.message }}</span>
            <a *ngIf="
                message.type !== 'text' &&
                message.type !== 'SMS' &&
                message.type === 'story_mention'
              " (click)="display(message)"><img src="{{ message.message }}" alt="message" /></a>
            <a *ngIf="
                message.type !== 'text' &&
                message.type !== 'SMS' &&
                message.type !== 'story_mention' &&
                message.type !== 'story_reply' &&
                message.type !== null
              " (click)="display(message)">
              <ng-container *ngIf="
                  message.type == 'image' ||
                  message.type == 'share'
                ">
                <img src="{{ message.message }}" width="200px" class="pointer" alt="message" />
              </ng-container>
              <ng-container *ngIf=" message.type == 'MMS'">
                <img src="{{ message.mediaAsset?.url }}" width="200px" class="pointer" alt="message" />
                <p>{{message.message}}</p>
              </ng-container>
              <ng-container *ngIf="message.type == 'video'">
                <div class="video-container">
                  <div class="play-button">
                    <em class="fa fa-play" aria-hidden="true"></em>
                  </div>
                  <video width="200px" class="pointer">
                    <source src="{{ message.message }}" />
                    <track kind="subtitles" src="subtitles.vtt" srclang="en" label="English" />
                    <track kind="description" src="description.vtt" srclang="en" label="English" />
                  </video>
                </div>
              </ng-container>
            </a>
            <ng-container *ngIf="
                message.type == 'audio' && message.mediaType == null
              ">
              <audio controls>
                <source src="{{ message.message || message.mediaAsset.url }}" />
              </audio>
            </ng-container>
            <a *ngIf="
                message.type !== 'text' &&
                message.type !== 'SMS' &&
                message.type == 'story_reply'
              " (click)="display(message)"><small>Replied to your story : </small>
            </a>
            <p *ngIf="
                message.type !== 'text' &&
                message.type !== 'SMS' &&
                message.type == 'story_reply'
              " class="mb-0">
              {{ message.message }}
            </p>
            <p *ngIf="message.type === null" class="mb-0">
              {{ message.message }}
            </p>
            <a *ngIf="
                message.type === 'SMS' && message.type !== null
              " (click)="display(message)">
              <ng-container *ngIf="message.mediaType?.includes('image')">
                <img src="{{ message.message }}" width="200px" class="pointer" alt="message" />
              </ng-container>
              <ng-container *ngIf="message.mediaType?.includes('video')">
                <div class="video-container">
                  <div class="play-button">
                    <i class="fa fa-play" aria-hidden="true"></i>
                  </div>
                  <video width="200px" class="pointer">
                    <source src="{{ message.message }}" />
                    <track kind="subtitles" src="subtitles.vtt" srclang="en" label="English" />
                    <track kind="description" src="description.vtt" srclang="en" label="English" />
                  </video>
                </div>
                <ng-container *ngIf="message.mediaType?.includes('application')">
                  <iframe src="{{ message.message }}" height="200" width="300" title="message"></iframe>
                </ng-container>
              </ng-container>
            </a>
            <ng-container *ngIf="
                message.type === 'SMS' &&
                message.mediaType?.includes('text')
              ">
              <span>{{ message.message }} </span>
            </ng-container>
            <ng-container *ngIf="
                message.type === 'SMS' &&
                message.mediaType?.includes('audio')
              ">
              <audio controls>
                <source src="{{ message.message || message.mediaAsset.url}}" />
              </audio>
            </ng-container>
            <!-- <p *ngIf="message.practitionerId == practionerId">
              <img
                src="./assets/img/mail-min.png "
                class="icon-img mail-img mb-1"
                alt="mail"
              /><span class="mail-sub mx-1">{{ message.subject }}</span
              ><br />{{ message.message }}
            </p> -->
            <p class="mt-1 small text-muted mb-0 text-captilize">
              <span class="text-muted"
                [ngClass]="{ 'pull-right': message.direction === 'INBOUND' }">&nbsp;&nbsp;&nbsp;{{
                message.updatedAt | date : "hh:mm a | MMMM d, y"
                }}</span>
            </p>
          </div>
        </ng-container>
        <p *ngIf="getSelectedChat?.length === 0 && !isChatLoader" class="small text-center pt-5">
          Your conversation box is empty.
        </p>
      </div>
    </div>
    <p *ngIf="error" class="text-danger text-center small">
      {{ sendMsgError }}
    </p>
    <p *ngIf="sending" class="text-muted font-italic small ml-4 mb-0">
      Sending...
    </p>
  </div>
</div>
<div *ngIf="showImgViewer" class="viewer-container">
  <a (click)="closeImgViewer()" class="pull-right pointer">
    <img src="./assets/img/close-btn.png" alt="esc" class="esc-icon-img" />
  </a>

  <ng-container *ngIf="selectedUrl">
    <ng-container *ngIf="selectedUrl.type !== 'SMS'">
      <div class="viewer-content" *ngIf="selectedUrl.type === 'image'">
        <img src="{{ selectedUrl.message }}" class="main-img" alt="icon-img" />
      </div>
      <div class="viewer-content" *ngIf="selectedUrl.type === 'video'">
        <video controls class="main-img">
          <track kind="subtitles" src="subtitles.vtt" srclang="en" label="English" />
          <track kind="description" src="description.vtt" srclang="en" label="English" />
          <source src="{{ selectedUrl.message }}" />
        </video>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedUrl.type === 'SMS'">
      <div class="viewer-content" *ngIf="selectedUrl.mediaType?.includes('image')">
        <img src="{{ selectedUrl.message }}" class="main-img" alt="icon-img" />
      </div>
      <div class="viewer-content" *ngIf="selectedUrl.mediaType?.includes('video')">
        <video controls class="main-img">
          <track kind="subtitles" src="subtitles.vtt" srclang="en" label="English" />
          <track kind="description" src="description.vtt" srclang="en" label="English" />
          <source src="{{ selectedUrl.message }}" />
        </video>
      </div>
    </ng-container>
  </ng-container>
</div>