import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../shared/modal/modal.component';
import { ModalConfig } from '../shared/modal/modal.config';
import { ActivatedRoute, Router } from '@angular/router';
import { FunnelStepsService } from './funnel-steps.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SidemenuService } from '../side-menu/sidemenu.service';
import { TopMenuService } from '../top-menu/top-menu.service';
import { AlertService } from '../utility/alert.service';

@Component({
  selector: 'app-funnel-steps',
  templateUrl: './funnel-steps.component.html',
  styleUrls: ['./funnel-steps.component.scss'],
})
export class FunnelStepsComponent implements OnInit {
  steps: any = [];
  stepName = '';
  funnelObj: any;
  seletedStepId: any = '';
  showError: boolean;
  preview: any;
  previewCss: any;
  googleFonts: any = [];
  selectedStepId = '';
  path = '';
  activeTab = 'header-code';
  headerScript = '';
  footerScript = '';
  imageDataUrl: any;
  funnelId: any;

  @ViewChild('stepDetails') private stepDetailsDialog: ModalComponent;
  @ViewChild('settingDetails') private settingDetailsDialog: ModalComponent;
  @ViewChild('trackingCodeSettings') private trackingCodedialog: ModalComponent;

  public stepDetailsConfig: ModalConfig = {
    modalTitle: 'New Step in Funnel',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };

  public settingDetailsConfig: ModalConfig = {
    modalTitle: 'Path Settings',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };
  public trackingCodeConfig: ModalConfig = {
    modalTitle: 'Tracking Code',
    hideDismissButton: () => {
      return true;
    },
    hideCloseButton: () => {
      return true;
    },
  };

  constructor(
    private ac: ActivatedRoute,
    private http: FunnelStepsService,
    private route: Router,
    private sanitizer: DomSanitizer,
    public nav: TopMenuService,
    public menu: SidemenuService,
    private alertService: AlertService
  ) {
    this.ac.queryParamMap.subscribe((params) => {
      this.funnelObj = { ...params };
    });
    // this.http.getGoogleFonts().subscribe(x => {
    //   const font = x?.items
    //   for (let i = 0; i < font.length; i++) {
    //     this.googleFonts.push({ value: (font[i].family).toString() + ', sans-serif', name: (font[i].family).toString() });
    //   }
    //   if (x.items && x.items.length > 0) {
    //     x.items.forEach((fontItem: any) => {
    //       this.loadGoogleFontDynamically(fontItem.family);
    //     })
    //   }
    // })
  }

  ngOnInit(): void {
    this.nav.hide();
    this.menu.show();
    this.http.getAllSteps(this.funnelObj?.params?.id).subscribe((res) => {
      this.steps = res;
      this.selectedStep(res[0]);
    });
  }
  createSteps() {
    if (this.stepName !== '') {
      this.closeDialog();
      this.showError = false;
      const req = {
        funnelId: this.funnelObj?.params?.id,
        stepName: this.stepName,
        data: {},
      };
      this.http.createStep(req).subscribe((res) => {
      if (res){
        this.alertService.success('Steps created successfully!');
      }
      this.selectedStep(res);
      this.stepName = '';
      this.getAllSteps();

      });
    } else {
      this.showError = true;
    }
  }
  openDialog() {
    this.stepDetailsDialog.open();
  }
  closeDialog() {
    this.stepDetailsDialog.close();
  }
  getAllSteps() {
    this.http.getAllSteps(this.funnelObj?.params?.id).subscribe((res) => {
      this.steps = res;
    });
  }
  deleteStep(step) {
    this.http.deleteStep(step).subscribe((res) => {
      this.alertService.success('Steps deleted successfully!');
      console.log('deleted');
      this.getAllSteps();
    });
  }
  selectedStep(step: any) {

    this.seletedStepId = step.id;
    this.funnelId = step.funnelId;
    this.http.getFunnelsById(this.funnelId).subscribe((funnels) => {
      this.headerScript = funnels.headerCode;
      this.footerScript = funnels.footerCode;
    });

    this.http.getStepsById(step.id).subscribe((res) => {
      if (!this.isEmptyObject(res.data) && res?.data?.imageDataUrl) {
        this.imageDataUrl = res?.data?.imageDataUrl;
        // this.preview = this.sanitizer.bypassSecurityTrustHtml(`${res?.data?.html}`);
        // this.previewCss = `${res?.data?.css}`;
        // var style = document.createElement("STYLE");
        // style.innerText = res?.data?.css;
        // document.getElementById('dy-style').prepend(style);
      } else {
        this.imageDataUrl = '';
        // this.preview = ``;
      }
    });
  }
  isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }
  editPage() {
    this.route.navigate(['/site'], { queryParams: { id: this.seletedStepId } });
  }

  settingDialog(val) {
    this.path = val.path;
    this.selectedStepId = val.id;
    this.settingDetailsDialog.open();
  }

  pathSettings() {
    if (this.path !== '') {
      this.showError = false;
      const req = {
        path: this.path,
      };

      this.http.createPathName(req, this.selectedStepId).subscribe((res) => {
        this.path = '';
        this.getAllSteps();
        this.settingDetailsDialog.close();
      });
    } else {
      this.showError = true;
    }
  }
  closeSettingDetailsDialog() {
    this.settingDetailsDialog.close();
  }
  addTrackingCode() {
    this.trackingCodedialog.open();
  }
  closeTrackingCodeDialog() {
    // this.headerScript = '',
    // this.footerScript= '',
    this.trackingCodedialog.close();
  }
  saveTrackingCode() {
    const obj = {
      id: this.funnelId,
      headerCode: this.headerScript,
      footerCode: this.footerScript,
    };
    this.http.funnelTrack(obj).subscribe((x) => {
      console.log(x, 'sucess');
    });
    this.trackingCodedialog.close();
  }
  search(activeTab) {
    this.activeTab = activeTab;
  }

  result(activeTab) {
    this.activeTab = activeTab;
  }
}
