<div class="row" id="body">
    <div class=" col-lg-12">
        <div class="main ">
            <nav class="custom-btns">
                <ul *ngIf="showHeaderBtns">
                    <li  title="back" (click)="goBack()"><i class="bi bi-arrow-left"></i></li>
                    <li class="dropdown">
                        <a href="javascript:void(0)">Settings &nbsp;<i class="bi bi-caret-down-fill"></i></a>
                        <div class="dropdown-content">
                            <a (click)="openSeoSettings()">SEO Meta Data</a>
                            <a (click)="openTrackingCodeSettings()"> Tracking Code </a>
                            <a (click)="openBackgroundSettings()">Background</a>
                            <a (click)="mypopUp()">Pop Up</a>
                            <!-- <a (click)="openTypographySettings()"> Typography </a> -->
                        </div>
                    </li>
                    <!-- <li (click)="hideSettings()">Hide Settings</li> -->
                    <li (click)="captureImage('save')">Save</li>
                    <li (click)="captureImage('preview')">Preview</li>
                </ul>
            </nav>

            <div id="gjs"></div>
            <!-- <div id="blocks" ></div> -->
        </div>
    </div>
</div>

<modal #seoSetting [modalConfig]="seoSettingConfig">
    <div class="text-left seo-config">
        <form [formGroup]="seoForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Title</label>
                        <input type="text" class="form-control" formControlName="title">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Description</label>
                        <textarea formControlName="description" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Keywords</label>
                        <input type="text" class="form-control" formControlName="keywords">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label">Author</label>
                        <input type="text" class="form-control" formControlName="author">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="mb-3">
                        <label class="form-label">Social Image</label>
                        <input type="file" class="form-control" formControlName="socialImage"
                            (change)="onFileChange($event)">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Custom Meta Tag</h6>
                </div>
                <div class="col-md-6 text-right">
                    <button type="button" (click)="addTag()" class="btn btn-blue">Add</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <ng-container formArrayName="tags">
                        <div *ngFor="let tag of getTags.controls; let i = index">
                            <div [formGroupName]="i" class="row mt-3">
                                <div class="col-md-5"><input formControlName="name" type="text" class="form-control"
                                        placeholder="Name" />
                                </div>
                                <div class="col-md-5"> <input formControlName="content" type="text" class="form-control"
                                        placeholder="Content" /></div>
                                <div class="col-md-2"> <button type="button" (click)="removeTag(i)"
                                        class="btn btn-danger">X</button></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
        <div class="mt-5 text-right">
            <button (click)="closeDialog()" class="btn btn-danger m-2">Cancel</button>
            <button (click)="seoSubmit()" class="btn btn-blue m-2">Update</button>
        </div>
    </div>

</modal>

<modal #deleteDialog [modalConfig]="deleteSetting">
    <div class="m-2">
        <p class="text-center">Are you sure you want to delete the selcted image ?</p>
        <div class="my-4">
            <button (click)="deleteAsset()" class="btn btn-danger mr-2 mb-2 small-text">
                Yes, delete it
            </button>
            <button (click)="closeDeleteDialog()" class="btn btn-secondary ml-2 mb-2 small-text">
                Cancel
            </button>
        </div>
    </div>
</modal>

<modal #trackingCodeModel [modalConfig]="TrackingCodeModelSetting">
    <div class="mx-2">
       <div class="row">
        <div id="tabs" #tabs>

            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link active" href="#header-code" [ngClass]="{ 'active':activeTab==='header-code'}" (click)="search('header-code')"
                   data-toggle="tab"> <i class="bi bi-code-slash"></i> Header Code </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#footer-code" [ngClass]="{ 'active':activeTab==='footer-code'}" data-toggle="tab"
                   (click)="result('footer-code')"> <i class="bi bi-code-slash"></i> Footer Code </a>
              </li>
            </ul>
          
            <div class="tab-content">
              <div class="tab-pane" id="header-code" [ngClass]="{ 'active':activeTab==='header-code'}">
               <textarea [(ngModel)]="headerScript" name="headerScript"></textarea>
              </div>
              <div class="tab-pane" id="footer-code" [ngClass]="{ 'active':activeTab==='footer-code'}">
                <textarea [(ngModel)]="footerScript" name="footerScript"></textarea>
            </div>
            </div>
          
          </div>
       </div>
        <div class="my-4">
            <button (click)="saveTrackingCode()" class="btn btn-danger mr-2 mb-2 small-text">
                Yes, Save
            </button>
            <button (click)="closeTrackingCodeDialog()" class="btn btn-secondary ml-2 mb-2 small-text">
                Cancel
            </button>
        </div>
    </div>
</modal>
