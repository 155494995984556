

<app-side-menu></app-side-menu>
<app-top-menu></app-top-menu>
<div class="wrapper ">
    <div class="app-container">
        <div class="row breadcrumbs">
            <div class="col-md-12">
                <h4 class="mb-0 header-title pr-3 float-left">
                    <!-- <img src="./assets/img/setttings-min.png" alt="embed" class="mb-1" height="18px" width="18px"> -->
                    <span class="pl-2">Funnels</span>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class=" col-lg-12">
                <div class="main ">
                    <div class="row">
                        <div class="col-3 left-box">
                            <div class="mt-4">
                                <h4 class="ml-5 "><i class="bi bi-check-circle-fill mr-2"></i><span class="ml-1">Launch
                                        Checklist</span>
                                </h4>
                            </div>
                            <hr>
                            <p class="ml-3 text-muted mt-3 ">Funnel steps</p>
                            <div class="boxes row" *ngFor="let step of steps">
                                <div class="col-2 icon">
                                    <i class="bi bi-envelope"></i>
                                </div>
                                <div class="col-7 text pointer">
                                    <span (click)="selectedStep(step)"> {{step.stepName}}</span>
                                </div>
                                <div class="col-3 text pointer">
                                    <span (click)="deleteStep(step)" class="text-danger"> X </span>
                                    <img src="./assets/img/setttings-min.png" alt="settings" (click)="settingDialog(step)"
                                     height="15px" width="15px" class="ml-3" title="Settings" >
                                </div>
                            </div>
                            <button class="btn btn-success ml-2 mt-2" (click)="openDialog()">Add New Step</button>
                        </div>
                        
                        <div class="col-9 center-box">
                            <div class="text-right mt-2">
                                <button class="btn btn-blue" (click)="addTrackingCode()"><i class="bi bi-code-slash"></i> Add Tracking Code</button>
                            </div>
                            <h5 class="text-center mt-5" *ngIf="steps.length === 0">Get started by adding a new step!</h5>
                            <ng-container *ngIf="steps.length > 0">
                                <div class="header container">
                                    <h6 class="mt-4"></h6>
                                    <hr>
                                    <p>Please add a domain in the settings to see your Funnel live!</p>
                                </div>
                                <div class="body container" >
                                    <div class="row">
                                        <div class="col-4">
                                            <span class="bg-primary text-white px-2 py-1"><i
                                                    class="bi bi-flag-fill mr-1"></i>control</span>
                                            <div class="step-box mt-1" id="dy-style" (click)="editPage()">
                                                <!-- <div  [innerHTML]="preview | keepHtml" #htmlContainer></div> -->
                                                <ng-container *ngIf="imageDataUrl"><img src="{{imageDataUrl}}"  width="100%" height="100%" /></ng-container>
                                                <ng-container *ngIf="!imageDataUrl">
                                                    <div class="m-5 p-3 d-flex justify-content-center">
                                                        <button class="btn btn-blue" (click)="editPage()" style="margin-top: 30px;">Create from Blank</button>
                                                    </div>
                                                </ng-container>
                                               
                                            </div>
                                            <button class="btn edit-btn px-3 py-1" (click)="editPage()">Edit</button>
                                        </div>
                                        <div class="col-4">
                                            <div>
                                                <img src="./assets/img/glass.png" class="ic-img " />
                                                <!-- <i class="bi bi-arrow-right"></i> -->
                                            </div>


                                            <h6 class="text-muted text-center mt-3">Start Split Test</h6>
                                            <p class="mt-1 text-muted text-small">Optimize your lead and sales
                                                generation with split tests.</p>
                                        </div>
                                        <div class="col-4">
                                            <span class="bg-secondary text-white px-2 py-1"><i
                                                    class="bi bi-flag-fill mr-1"></i>variation</span>
                                            <div class="step-box2 mt-1">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><modal #stepDetails [modalConfig]="stepDetailsConfig">
        <form class="m-2">
            <label>Name for Page</label>
            <input [(ngModel)]="stepName" name="stepName" class="form-control"/>
            <small *ngIf="showError" class="text-danger">Required field</small>
        </form>
        <div class="mt-5 text-center">
            <button (click)="createSteps()" class="btn btn-blue m-1">Create</button>
            <button (click)="closeDialog()" class="btn btn-danger m-1">Cancel</button>
        </div>
    </modal>
</div>

<modal #settingDetails [modalConfig]="settingDetailsConfig">
    <form class="m-2" (ngSubmit)="pathSettings()">
        <label class="">Enter path name </label>
        <input [(ngModel)]="path" name="path" class="form-control"/>
        <small *ngIf="showError" class="text-danger">Required field</small>
    </form>

    <div class="mt-5 text-center">
        <button (click)="pathSettings()" class="btn btn-blue m-1">Submit</button>
        <button (click)="closeSettingDetailsDialog()" class="btn btn-danger m-1">Cancel</button>
    </div>
</modal>
<modal #trackingCodeSettings [modalConfig]="trackingCodeConfig">
    <div class="mx-2">
        <div class="row">
         <div id="tabs" #tabs>
 
             <ul class="nav nav-pills">
               <li class="nav-item">
                 <a class="nav-link active" href="#header-code" [ngClass]="{ 'active':activeTab==='header-code'}" (click)="search('header-code')"
                    data-toggle="tab"> <i class="bi bi-code-slash"></i> Header Code </a>
               </li>
               <li class="nav-item">
                 <a class="nav-link" href="#footer-code" [ngClass]="{ 'active':activeTab==='footer-code'}" data-toggle="tab"
                    (click)="result('footer-code')"> <i class="bi bi-code-slash"></i> Footer Code </a>
               </li>
             </ul>
           
             <div class="tab-content">
               <div class="tab-pane" id="header-code" [ngClass]="{ 'active':activeTab==='header-code'}">
                <textarea [(ngModel)]="headerScript" name="headerScript"></textarea>
               </div>
               <div class="tab-pane" id="footer-code" [ngClass]="{ 'active':activeTab==='footer-code'}">
                 <textarea [(ngModel)]="footerScript" name="footerScript"></textarea>
             </div>
             </div>
           
           </div>
        </div>
         <div class="my-4">
             <button (click)="saveTrackingCode()" class="btn btn-danger mr-2 mb-2 small-text">
                 Yes, Save
             </button>
             <button (click)="closeTrackingCodeDialog()" class="btn btn-secondary ml-2 mb-2 small-text">
                 Cancel
             </button>
         </div>
     </div>
</modal>