import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-activity',
  templateUrl: './lead-activity.component.html',
  styleUrls: ['./lead-activity.component.scss']
})
export class LeadActivityComponent implements OnInit {
@Input() leadForm: any;
  constructor() {
    // empty
  }

  ngOnInit(): void {
    this.leadForm?.formData.forEach(el => {
      el.question = el.question.replace(/[^a-zA-Z0-9]/g, ' ');
    });
  }

}
