 import { Pipe, PipeTransform, SecurityContext  } from '@angular/core';
 import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

 @Pipe({ name: 'keepHtml', pure: false })
 export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any) {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value));
  }
 }
