<app-side-menu></app-side-menu>
<app-top-menu></app-top-menu>
<div class="wrapper ">
    <div class="app-container">
        <div class="row breadcrumbs">
            <div class="col-md-12">
                <h4 class="mb-0 header-title pr-3 float-left">
                    <!-- <img src="./assets/img/setttings-min.png" alt="embed" class="mb-1" height="18px" width="18px"> -->
                    <span class="pl-2">Funnels</span>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class=" col-lg-12">
                <div class="main ">
                    <div class="text-right"> <button type="button" class="btn btn-blue m-1" (click)="openDialog()">
                            New funnel </button></div>
                    <div class="table-responsive">
                        <table class="table table-bordered text-center shadow">
                            <thead class="table-primary">
                                <tr>
                                    <th id="field-name">Name</th>
                                    <th id="field-name">Last updated</th>
                                    <th id="field-name">Items</th>
                                    <th id="field-name">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let funnel of funnelsList">
                                    <td class="names" (click)="steppage(funnel)">{{funnel.funnelName}}</td>
                                    <td class="names" (click)="steppage(funnel)">{{funnel.updatedAt | date}}</td>
                                    <td class="names" (click)="steppage(funnel)">{{funnel.stepsCount}}</td>
                                    <td class="text-danger"><span (click)="deleteFunnel(funnel)">
                                            <img src="./assets/img/delete-icon.jpg" alt="delete" height="15px"
                                                width="15px" class="ml-2" title="Delete">
                                        </span><span (click)="settingDialog(funnel)"><img
                                                src="./assets/img/setttings-min.png" alt="settings" height="15px"
                                                width="15px" class="ml-2" title="Settings"></span></td>
                                </tr>

                                <tr *ngIf="funnelsList.length == 0">
                                    <td colspan="4">No Records</td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="table-desc" style="display: none">table description</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<modal #funnelDetails [modalConfig]="funnelDetailsConfig">
    <form class="m-2">
        <label>Funnel Name </label>
        <input [(ngModel)]="funnelName" name="funnelName" class="form-control" />
        <small *ngIf="showError" class="text-danger">Required field</small>
    </form>
    <div class="mt-5 text-center">
        <button (click)="createFunnel()" class="btn btn-blue m-1">Create</button>
        <button (click)="closeFunnelDetailsDialog()" class="btn btn-danger m-1">Cancel</button>
    </div>
</modal>

<modal #settingDetails [modalConfig]="settingDetailsConfig">
    <form class="m-2" (ngSubmit)="createSettingFunnel()">
        <div class="form-group">
            <label class="">Enter domain </label>
            <input type="text" [(ngModel)]="domainLink" name="domainLink" class="form-control" />
            <small *ngIf="showError" class="text-danger">Required field</small>
        </div>
        <div class="form-group">
            <h6><b>Set up your domain manually by addingthe following records</b></h6>
            <div class="d-flex">
                <div>
                    <label class="">Record </label>
                    <input type="text" value="A" class="form-control" readonly />
                </div>
                <div class="mx-2">
                    <label class="">Host </label>
                    <input type="text" value="@" class="form-control" readonly />
                </div>
            </div>
            <div class=" my-2">
                <label>Required Value </label>
                <div class="input-group">
                    <input type="text" class="form-control" [(ngModel)]="domainValue" name="domainValue"
                        class="form-control" #userinput readonly>
                    <div class="input-group-append">
                        <span class="input-group-text" (click)="copyInputMessage(userinput)"><i class="fa fa-clipboard"
                                aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="mt-5 text-center">
        <button (click)="createSettingFunnel()" class="btn btn-blue m-1">Submit</button>
        <button (click)="closeSettingDetailsDialog()" class="btn btn-danger m-1">Cancel</button>
    </div>
</modal>