import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { GlobalProvider } from './global.service';
import { map, catchError } from 'rxjs/operators';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    public global: GlobalProvider,
    private alert: AlertService
  ) { }

  post(endpoint: string, reqBody: any, auth: boolean): Observable<any> {
    let headers = new HttpHeaders();
    if (auth) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    return this.http.post(endpoint, reqBody, { headers }).
      pipe(catchError(error => {
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        } else {
          if (error.status === 401 && !endpoint.includes('login')) {
            this.alert.error(error.status);
          }

          httpError = this.getServerErrorMessage(error, endpoint);
        }
        return throwError(httpError);
      })
      );
  }

  put(endpoint: string, reqBody: any, auth: boolean): Observable<any> {
    let headers = new HttpHeaders();
    if (auth) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    return this.http.put(endpoint, reqBody, { headers }).
      pipe(catchError(error => this.handleError(error))
      );
  }

  get(endpoint: string, auth: boolean): Observable<any> {
    let headers = new HttpHeaders();
    if (auth) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    return this.http.get(endpoint, { headers }).
      pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          if (error.status === 401) {
            this.alert.error(error.status);
          }
          return throwError('Something went wrong!');
        })
      );
  }


  del(endpoint: string, auth: boolean): Observable<any> {
    let headers = new HttpHeaders();
    if (auth) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    return this.http.delete(endpoint, { headers }).
      pipe(catchError(error => this.handleError(error))
      );

  }
  handleError(error: any): Observable<string> {
    let httpError: string;
    if (error.error instanceof ErrorEvent) {
      httpError = `Error: ${error.error.message}`;
    } else {
      httpError = this.getServerErrorMessage(error);
    }
    return throwError(httpError);
  }


  private getServerErrorMessage(error: HttpErrorResponse, url?: string): string {
    switch (error.status) {
      case 401: {
        return 'Please enter a valid username and password.';
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      case 400: {
        if (url.includes('/fb/message')) {
          return error.error?.data;
        }else{ return 'Something went wrong.Please try again.';
        }
      }
      default: {
        return 'Something went wrong.Please try again.';
      }

    }
  }

  patch(endpoint: string, reqBody?: any, auth?: boolean): Observable<any> {
    let headers = new HttpHeaders();
    if (auth) {
      headers = headers.set('Authorization', localStorage.getItem('accessToken'));
    }
    return this.http.patch(endpoint, reqBody, { headers }).
      pipe(catchError(error => {
        let httpError: string;
        if (error.error instanceof ErrorEvent) {
          httpError = `Error: ${error.error.message}`;
        }
        else {
          if (error.status === 401) {
            this.alert.error(error.status);
          }
          httpError = this.getServerErrorMessage(error);
        }
        return throwError(httpError);
      })
      );
  }
}
