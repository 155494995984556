 // loader.interceptors.ts
 import { Injectable } from '@angular/core';
 import {
     HttpResponse,
     HttpRequest,
     HttpHandler,
     HttpEvent,
     HttpInterceptor
 } from '@angular/common/http';
 import { Observable, Observer } from 'rxjs';
 import { LoaderService } from './loader.service';

 @Injectable()
 export class LoaderInterceptor implements HttpInterceptor {
     private requests: HttpRequest<any>[] = [];

     constructor(private loaderService: LoaderService) { }

     removeRequest(req: HttpRequest<any>) {
         const i = this.requests.indexOf(req);
         if (i >= 0) {
             this.requests.splice(i, 1);
         }
         this.loaderService.isLoading.next(false);
     }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('conversation') || req.url.includes('integrate')) {
          this.loaderService.isLoading.next(false);
        } else {
          this.loaderService.isLoading.next(true);
        }
        this.requests.push(req);
        return new Observable((observer: Observer<HttpEvent<any>>) => {
          const subscription = next.handle(req)
            .subscribe(
              (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.removeRequest(req);
                  observer.next(event);
                }
              },
              (err: any) => {
                this.removeRequest(req);
                observer.error(err);
              },
              () => {
                this.removeRequest(req);
                observer.complete();
              }
            );
          // remove request from queue when cancelled
          return () => {
            this.removeRequest(req);
            subscription.unsubscribe();
          };
        });
      }

 }
