<div class="h-100">
    <div class="row h-100">
        <div class="col-md-12  text-center login-col-padding">
            <div class="d-flex logo">
                <div class=" text-left">
                    <span>Hipokratiz</span>
                </div>
            </div>
            <p class="text-danger text-center text-error m-3"><small class="mr-50">{{authError}}</small></p>
            <div class="row ">
                <div class="col-lg-4 col-md-6 col-sm-8 col-9 card m-auto shadow p-md-5 p-4">
                    <h3 class="mb-4 font-weight-bold ">Welcome</h3>
                    <p class=" ml-2">Log in to your Irine account.</p>
                    <form class="login-form  " [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="router.url === '/login'">
                        <div class="group mb-4">
                            <label class="form-label" for="inputEmailAddress">Email address</label>
                            <input type="text" formControlName="username" class="form-control" type="email" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="inputEmailAddress" />
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Username is required</div>
                                <div *ngIf="f.username.errors.pattern">Please enter a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label class="form-label" for="inputPassword">Password</label>
                            <input type="password" formControlName="password" class="form-control " [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="inputPassword" />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <button [disabled]="loading" class="btn btn-blue w-100 mb-4">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Continue
                        </button>
                        <div class="form-group text-center">
                            <a class="link small" routerLink="/forgot-password">Forgot Password?</a>
                        </div>
                        <div class="form-group text-center">
                            <p class="small">By signing in you agree to our <a href="https://www.hipokratiz.com/privacy" target="_blank"> Terms and Conditions </a></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
