import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../../utility/http.service';
import { environment } from 'src/environments/environment';
import { WebsocketService } from './ws-service';

export class Message {
  constructor(public author: string, public content: string) { }
}

@Injectable()
export class ChatService {

  constructor(private http: HttpService, private websocketService: WebsocketService) {
  }

  conversation = new Subject<Message[]>();
  headers = new HttpHeaders();

  onNewMessage() {
    return new Observable(observer => {
      this.websocketService.messages.subscribe(msg => {
        // console.log('Response from websocket: ' + msg);
        observer.next(msg);
      });
    });
  }

  getConversation(virtualNumber) {
    return this.http.get(environment.baseUrl + 'conversation/fb/allmessage?virtualNumber=' + virtualNumber
      + '&facebookPageId=' + localStorage.getItem('pageId') + '&agentId=' + localStorage.getItem('gmbId'), true);
  }
  sendMessage(body) {
    return this.http.post(environment.baseUrl + 'tps-twilio/v1/sms', body, true);
  }
  getPracticeList() {
    return this.http.get(environment.baseUrl + 'practitioner/v1/practitioner/' + localStorage.getItem('practitionerID'), true);
  }
  getQuestionnaire(id) {
    return this.http.get(environment.baseUrl + 'patient/form/submission/patient/' + id, true);
  }
  getPhoneNumber(id) {
    return this.http.get(environment.baseUrl + 'conversation/practice/' + id, true);

  }
  getAllPatients(id: any) {
    return this.http.get(environment.baseUrl + 'patient/patients?practitionerID=' + id, true);
  }
  getPatientByPhone(phoneNumber) {
    return this.http.get(environment.baseUrl + 'patient/phone/' + phoneNumber, true);
  }
  getChatDetails(userId) {
    return this.http.get(`${environment.baseUrl}landbot/conversation/activity/${userId}/${localStorage.getItem('practitionerID')}`, true);
  }
  getFbConversations() {
    return this.http.get(environment.baseUrl + 'tps-meta/v1/?pageId=' + localStorage.getItem('pageId'), true);
  }
  sendMessageToPage(body) {
    return this.http.post(environment.baseUrl + 'tps-meta/v1', body, true);
  }
  sendMessageTogbm(body) {
    return this.http.post(environment.baseUrl + 'tps-google/v1/sendMessage', body, true);
  }
  twiloCall(phone: any) {
    return this.http.post(environment.baseUrl + 'tps-twilio/v1/call/webhook', phone, true);
  }
  generateToken(virtualNumber: any) {
    return this.http.post(environment.baseUrl + 'tps-twilio/v1/call/token', virtualNumber, true);
  }
  getPractitionerList() {
    return this.http.get(environment.baseUrl + 'practitioner/v1/practitioner/list', true);
  }
  getPractitionerDetails(id: string) {
    return this.http.get(environment.practitionerUrl + 'practitioner/' + id, true);
  }
  postMail(body) {
    return this.http.post(environment.baseUrl + 'tps-mailgun/v1/', body, true);
  }
  receiveMail() {
    return this.http.get(environment.baseUrl + 'tps-mailgun/v1/' + localStorage.getItem('practitionerID'), true);
  }

  getAllSysForms() {
    return this.http.get(environment.baseUrl + 'marketing/systemForms/' + localStorage.getItem('practitionerID'), true);
  }
  getAllFbLeads() {
    return this.http.get(environment.baseUrl + 'tps-meta/v1/connection/leadGenForms/' + localStorage.getItem('pageId'), true);
  }

  getTwilioMessages(details: any) {
    return this.http.get(`${environment.baseUrl}conversation-bff/v1/patient/${details.userId}/${localStorage.getItem('practitionerID')}/${details.pageNumber}`, true);
  }
  getMetaMessages(details: any) {
    return this.http.get(environment.baseUrl + 'conversation-bff/v1/patient/metaMessage/'
      + localStorage.getItem('practitionerID') + '/' + details.patientPageId + '/' + details.pageNumber, true);
  }
  getGmbMessages(value: any) {
    return this.http.get(environment.baseUrl + 'conversation-bff/v1/patient/gmbMessage/'
      + localStorage.getItem('practitionerID') + '/' + value.conversationId + '/' + value.pageNumber, true);
  }

  getNotes(userId: string, type: string) {
    return this.http.get(`${environment.baseUrl}patient-be/v1/${type}/${userId}/${localStorage.getItem('practitionerID')}`, true);
  }
  GetNotesById(id: string, type: string) {
    return this.http.get(`${environment.baseUrl}patient-be/v1/${type}/${id}`, true);
  }
  createNote(body, type: string) {
    return this.http.post(`${environment.baseUrl}patient-be/v1/${type}`, body, true);
  }
  updateNote(body, type: string) {
    return this.http.patch(`${environment.baseUrl}patient-be/v1/${type}/update`, body, true);
  }
  DeleNotesById(id: string, type: string) {
    return this.http.del(`${environment.baseUrl}patient-be/v1/${type}/${id}`, true);
  }
  markAsStarred(req: any) {
    return this.http.patch(`${environment.baseUrl}conversation-bff/v1/starred`, req, true);
  }
  markAsUnRead(req: any) {
    return this.http.patch(`${environment.baseUrl}conversation-bff/v1/unread`, req, true);
  }
  getSearchResults(data: any) {
    let url = `${environment.baseUrl}conversation-bff/v1/filter?practitionerId=${localStorage.getItem('practitionerID')}`;
    let segments = [];
    if (data.direction) {
      segments.push(`direction=${data.direction}`);
    }
    if (data.operator) {
      segments.push(`operator=${data.operator}`);
    }
    if (data.type) {
      segments.push(`key=${data.type}`);
    }
    if (data.word) {
      segments.push(`search=${data.word}`);
    }
    segments.push(`page=${data.pageNumber}`);
    url += `&${segments.join('&')}`;
    return this.http.get(url, true);
  }

}
