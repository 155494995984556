<ng-template #modal>
    <div class="modal-header" [ngClass]="{'border-bottom': header === 'true'}" *ngIf="modalConfig.modalTitle !== '' ">
        <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
    </div>
    <div class="modal-body" [ngClass]="{'text-left': header === 'true'}" style="width:auto">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!modalConfig.hideDismissButton || !modalConfig.hideCloseButton">
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="dismiss()" *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()" [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
        {{ modalConfig.dismissButtonLabel }}
      </button>
        <button type="button" class="btn btn-outline-primary" (click)="close()" *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()" [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div>
    </ng-container>
</ng-template>