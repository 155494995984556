<ng-container *ngIf="irine.welcome !== null ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Need help with</p>
            <p class="ans mb-0">{{irine.welcome}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.question !== null ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Patient’s question</p>
            <p class="ans mb-0">{{irine.question}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.reasonForAppointment !==  null ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Reason for appointment</p>
            <p class="ans mb-0">{{irine.reasonForAppointment}}</p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="irine.WhatIsGoingOn !== null ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">What’s going on</p>
            <p class="ans mb-0">{{irine.WhatIsGoingOn}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.insuranceQuestion !==  null ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Patient’s question on insurance</p>
            <p class="ans mb-0">{{irine.insuranceQuestion}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.insurance !== null && irine.welcome === 'Need Dental Help Now' ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Patient’s insurance</p>
            <p class="ans mb-0">{{irine.insurance}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.whoIsThis !== null">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">New or existing patient</p>
            <p class="ans mb-0">{{irine.whoIsThis}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.wantAnApptmt !== null && irine.welcome !== 'Want An Appointment' ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Want An Appointment</p>
            <p class="ans mb-0">{{irine.wantAnApptmt}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.location !== null ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Location preference</p>
            <p class="ans mb-0">{{irine.location}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.whoThisIsFor !== null">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">If for someone, who this is for</p>
            <p class="ans mb-0">{{irine.whoThisIsFor}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.whenYouWantRspns !== null">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">When they want a response</p>
            <p class="ans mb-0">{{irine.whenYouWantRspns}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.wantAnApptmt !== null && irine.welcome === 'Something Else'">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Want to make an appointment</p>
            <p class="ans mb-0">{{irine.wantAnApptmt}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.preferredTime !== null">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Time preference</p>
            <p class="ans mb-0">{{irine.preferredTime}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.preferredDay !== null">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Day preference</p>
            <p class="ans mb-0">{{irine.preferredDay}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="irine.insurance !== null && irine.welcome === 'Want An Appointment' ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">Patient’s insurance</p>
            <p class="ans mb-0">{{irine.insurance}}</p>
        </div>
    </div>
</ng-container>
<ng-container *ngFor="let submission of irine.fieldData ">
    <div class="form-disc my-4">
        <div class="que-line">
            <p class="ques mb-2">{{submission.question}}</p>
            <p class="ans mb-0">{{submission.answer}}</p>
        </div>
    </div>
</ng-container>
