import { Component, Injectable, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ModalConfig } from './modal.config';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],

})
@Injectable()
export class ModalComponent implements OnInit {
  @Input() public modalConfig: ModalConfig;
  @Input() public header: string;
  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;
  private modalRef: NgbModalRef;

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
    // console.log('modal');

   }

  open() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.modalRef = this.modalService.open(this.modalContent, ngbModalOptions);
    this.modalRef.result.then();
  }

  public close() {
    this.modalRef?.close();
  }

  public dismiss() {
    this.modalRef.dismiss();
  }
}
