<app-side-menu [call]="stopHideShow"></app-side-menu>
<app-top-menu (changePractitinor)="selectPractitioner($event)"></app-top-menu>
<div class="veiw-height desktop" [ngClass]="screenWidth >= 992 ? 'show' : 'hide'">
  <div class="app-container">
    <ng-container *ngIf="isShowError && newCn.length === 0">
      <p class="small text-center pt-5">Your conversation box is empty.</p>
    </ng-container>
    <div class="row" >
      <div class="col-md-3 left-panel">
        <div class="chat-list left-panel-content mt-3 card">
          <div class="filters-tab">
            <ul>
              <li *ngFor="let filter of filterTypes" [ngClass]="{'selected-filter': filter.value === selectedFilter}" (click)="selectTypeOfFilter(filter)">{{filter.type}}</li>
            </ul>
          </div>
          <div class="d-flex mt-3">
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchTerm"
                (input)="searchContact($event.target.value)">
                <div class="input-group-append">
                  <span class="fa fa-search "></span>
                </div>
              </div>
            <div class="my-2 mx-1">
              <span class="pointer text-primary"  title="Filters" (click)="openQuickFilter()">
                <i class="fa fa-filter"></i> <span class="filter-count" *ngIf="filterCount">{{filterCount}}</span>
              </span>
            </div>
            <div class="my-2 mx-1">
              <span class="pointer text-primary" (click)="newMessage()" title="New Message">
                <i class="fa fa-edit"></i>
              </span>
            </div>
          </div>
          <div class="custom-scroll">
            <div class="l-panel">
              <div class="m13 divider-line" *ngFor="let list of newCn; index as i" (click)="selectChat(list) ">
                <div class="d-flex profile pointer" [ngClass]="{
                    'selected-profile':
                      list.id === selectChatIndex &&
                      list.operator === selectedOperator
                  }" *ngIf="list.toUsername !== null && list.operator">
                  <div class="mx-2 my-3 name-icon">
                    <p class="profile-img mb-0 text-uppercase">
                      {{
                      ((list.operator === "GBM" &&
                      list.messageType === "OUTBOUND") ||
                      list.operator === "TWILIO"
                      ? list.patientName
                      : list.patientName
                      ) | slice : 0 : 2
                      }}
                      <ng-container *ngIf="
                          (phoneNumber === list.receiver ||
                            phoneNumber === list.sender) &&
                            stopHideShow === true;
                          else img
                        ">
                        <span><span class="operator"><img src="./assets/img/call-icon.png" alt="call"
                              width="16px" /></span></span>
                      </ng-container>
                      <ng-template #img>
                        <span class="operator"><img src="./assets/img/{{ list.operator }}.png" alt="call-icon"
                            width="16px" /></span>
                      </ng-template>
                    </p>
                  </div>

                  <div class="width-100">
                    <div class="m-1 prof-name">
                      <p class="font-weight-bold text-capitalize mb-0 mt-3 mb-1 ml-2">
                        {{
                        (list.operator === "GBM" &&
                        list.messageType === "OUTBOUND") ||
                        list.operator === "TWILIO"
                        ? list.patientName
                        : list.patientName
                        }}
                      </p>

                      <div class="last-msg">
                        <!-- <ng-container *ngIf="list.operator === 'TWILIO'">
                        </ng-container> -->
                        <ng-container *ngIf="
                              list.direction == 'OUTBOUND'
                            ">
                          <p class="capitalize wraped-text mb-0" *ngIf="list.type !== 'audio' && list.type !== 'image'">
                            {{ list.message | slice : 0 : 56 }}
                          </p>
                          <ng-container *ngIf="
                                (phoneNumber === list.receiver ||
                                  phoneNumber === list.sender) &&
                                stopHideShow === true
                              ">
                            <p>
                              Ongoing call
                              <img src="assets/img/on-call-no-bg.gif" width="15px"
                                style="float: right; margin-left: 5px" alt="on-call" />
                            </p>
                          </ng-container>
                          <ng-container *ngIf="
                                (phoneNumber === list.receiver ||
                                  phoneNumber === list.sender) &&
                                stopHideShow === false
                              ">
                            <p *ngIf="list.type === 'audio'">
                              <img src="assets/img/outgoing_phone_icon.png" width="15px" alt="phone-icon" />
                              Outgoing call
                            </p>
                          </ng-container>
                          <ng-container>
                            <p *ngIf="
                                list.type === 'audio' &&
                                  phoneNumber !== list.receiver
                                ">
                              <img src="assets/img/outgoing_phone_icon.png" width="15px" alt="audio" />
                              Outgoing call
                            </p>
                            <p *ngIf="list.type === 'image'">
                              <img src="assets/img/image_icon.png" width="15px" alt="image_icon" />
                              Image Sent
                            </p>
                          </ng-container>

                          <small class="pull-right font-weight-light timer-text">
                            {{ list.timestamp | messageTime }}</small>
                        </ng-container>
                        <ng-container *ngIf="
                              list.direction === 'INBOUND' &&
                              list.sender === list.phone
                            ">
                          <p class="capitalize wraped-text mb-0" *ngIf="list.type !== 'audio' && list.type !== 'image'">
                            {{ list.message | slice : 0 : 56 }}
                          </p>
                          <p *ngIf="list.type === 'image'">
                            <img src="assets/img/image_icon.png" width="15px" alt="image_icon" />
                            Image Received
                          </p>
                          <p *ngIf="list.type === 'audio'">
                            <img src="assets/img/incoming_phone_icon.png" width="15px" alt="incoming_phone_icon" />
                            Incoming Call
                          </p>
                          <small class="pull-right font-weight-light timer-text">
                            {{ list.timestamp | messageTime }}</small>
                        </ng-container>
                        <ng-container *ngIf="
                              list.direction === 'INBOUND' &&
                              list.conversationId === list.phone
                            ">
                          <p class="capitalize wraped-text mb-0">
                            {{ list.message | slice : 0 : 56 }}
                          </p>
                          <small class="pull-right font-weight-light timer-text">
                            {{ list.timestamp | messageTime }}</small>
                        </ng-container>
                        <ng-container *ngIf="
                              list.direction === 'OUTBOUND' &&
                              list.conversationId === list.phone
                            ">
                          <p class="capitalize wraped-text mb-0">
                            {{ list.message | slice : 0 : 56 }}
                          </p>
                          <small class="pull-right font-weight-light timer-text">
                            {{ list.timestamp | messageTime }}</small>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h6 class="text-primary pointer text-center" *ngIf="practitionerCount >= 10"><small
              (click)="loadOldPractitioners()" class="load-more-text">Load More</small></h6>
        </div>
      </div>

      <ng-container *ngIf="showChatBox != ''">
        <div class="col-md-9 right-panel" style="padding: 10% 20%;">
          <p>{{showChatBox}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="showChatBox == ''">
        <div class="col-md-5 right-panel">
          <div *ngIf="selectedChat">
            <app-chat-box [selectedChat]="selectedChat" [reloadChat]="reloadChat" [practionerId]="practionerId"
              [getSelectedChat]="getSelectedChat" [isChatLoader]="isChatLoader" [error]="error" [sending]="sending"
              [questionnaire]="questionnaire" [stopHideShow]="stopHideShow"
              [selectedLocationNumber]="selectedLocationNumber" [device]="device" [practitioner]="practitioner"
              [lengthOfConversation]="lengthOfConversation"
              (increasePageNumber)="getconversations($event)" (getupdatedConversation)="updateConversationStatus()"></app-chat-box>
            <!---------->
            <div class="card">
              <div class=" ">
                <div id="tabs" #tabs class="tabbs">
                  <ul class="nav nav-pills">
                    <li class="nav-item"
                      *ngIf="selectedChat.patientNumber || (selectedChat?.operator !== 'TWILIO' && selectedChat?.operator !== 'MAILGUN')">
                      <a class="nav-link" href="#sms" [ngClass]="{
                          active: activetab === 'search' || activetab === 'result'
                        }" (click)="search('search', $event)" data-toggle="tab">
                        {{
                        (selectedChat?.operator == "TWILIO" || selectedChat?.operator == "MAILGUN") ? "SMS" : "Message"
                        }}</a>
                    </li>
                    <li class="nav-item"
                      *ngIf="(selectedChat.operator == 'TWILIO' || selectedChat?.operator == 'MAILGUN') && selectedChat?.patientMail">
                      <a class="nav-link" href="#mail" [ngClass]="{ active: activetab === 'styles' }" data-toggle="tab"
                        (click)="styles('styles', $event)">Email</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane" id="sms" [ngClass]="{ active: activetab === 'search' }">
                      <div class="send-message m-2" [ngClass]="{
                          'border-danger': !textMessage && emptyMessage
                        }">
                        <textarea [(ngModel)]="textMessage" rows="3" (keydown.enter)="onKeydown($event)"
                          (keyup.enter)="sendMessage(selectedChat, textMessage)" name="textMessage " type="text "
                          maxlength="1600 " placeholder="Type your message"></textarea>
                        <button (click)="sendMessage(selectedChat, textMessage)">
                          <img src="./assets/img/send-min.png " class="icon-img" alt="mail " />
                        </button>
                      </div>
                    </div>
                    <div class="tab-pane" id="mail" [ngClass]="{ active: activetab === 'styles' }"
                      *ngIf="selectedChat.operator == 'TWILIO' || selectedChat.operator == 'MAILGUN'">
                      <div class="small error p-2 mt-2 my-4 alert-danger unverify text-center bounce" *ngIf="dmErr">
                        Email service has not been set up yet. <br />Go to
                        <span class="text-c" (click)="navPage()">
                          <img src="./assets/img/emails.png" alt="Integrations" height="20px" width="20px"
                            class="mt-1" />
                          email settings
                        </span>
                        and add your domain to start sending emails.
                      </div>
                      <ng-container *ngIf="!dmErr">
                        <div class="border m-2 py-1 px-2 border-rounded">
                          <div class="row pt-1" style="height: auto">
                            <div class="col-md-6 form-group mb-0">
                              <label class="mb-0"><small>From :</small></label>
                              <input type="text" placeholder="{{ domainUser }}@{{ domainName }}" disabled
                                class="small form-control" />
                            </div>
                            <div class="col-md-6 form-group mb-0">
                              <label class="mb-0"><small>To :</small></label>
                              <input type="text" placeholder="{{ selectedChat?.patientMail }}" disabled
                                class="small form-control" />
                            </div>
                          </div>
                          <form [formGroup]="sendEmail">
                            <div class="form-group input-adj my-2">
                              <input type="text" class="small form-control" formControlName="subject"
                                placeholder="Subject" />
                            </div>
                            <div class="send-message" [ngClass]="{
                                'border-danger': !textMessage && emptyMessage
                              }">
                              <textarea type="text " maxlength="1600 " formControlName="message" rows="3"
                                placeholder="Type your mail"></textarea>
                            </div>
                          </form>
                          <div class="text-right">
                            <button (click)="sendMail(selectedChat?.patientMail)" class="btn btn-blue m-2">
                              <em class="bi bi-send mr-2"></em> Send
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 right-panel">
          <app-activity-section [selectedChat]="selectedChat" [irineChatRev]="irineChatRev"
            [fbLeadForm]="fbLeadForm"></app-activity-section>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- mob view -->
<div class="wrapper" [ngClass]="screenWidth >= 992 ? 'hide' : 'show'">
  <div class="row h-100">
    <div class="col-md-12">
      <div class="app-container">
        <ng-container *ngIf="isShowError">
          <p class="small text-center pt-5">Your conversation box is empty.</p>
        </ng-container>
        <ng-container *ngIf="!mobileChat">
          <button class="btn float" (click)="newMessage()">
            <img src="./assets/img/message.png" alt="msg" class="plus-icon-img" />
          </button>
          <div class="form-group has-search" style="padding: 20px 10px 10px;">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchTerm"
              (input)="searchContact($event.target.value)">
          </div>
          <div class="profile divider-line" *ngFor="let list of newCn; index as i" (click)="showChatList(list)">
            <div class="d-flex my-2" *ngIf="list.name !== null">
              <div class="name-icon">
                <p class="profile-img mb-0">
                  {{
                  (list.operator === "TWILIO"
                  ? list.patientName
                  : list.patientName
                  ) | slice : 0 : 2
                  }}
                  <span class="operator">
                    <img src="./assets/img/{{ list.operator }}.png" width="16px" alt="image_icon" /></span>
                </p>
              </div>
              <div class="mb-1 width-100">
                <p class="mt-1 ml-2 mb-1 small font-weight-bold text-capitalize">
                  {{
                  list.operator === "TWILIO"
                  ? list.patientName
                  : list.patientName
                  }}
                </p>
                <ng-container *ngIf="
                        list.direction == 'OUTBOUND'
                      ">
                  <p class="capitalize wraped-text ml-2" *ngIf="list.type !== 'audio' && list.type !== 'image'">
                    {{ list.message | slice : 0 : 56 }}
                  </p>
                  <ng-container *ngIf="
                          (phoneNumber === list.receiver ||
                            phoneNumber === list.sender) &&
                          stopHideShow === true
                        ">
                    <p>
                      Ongoing call
                      <img src="assets/img/on-call-no-bg.gif" width="15px" style="float: right; margin-left: 5px"
                        alt="on-call" />
                    </p>
                  </ng-container>
                  <ng-container *ngIf="
                          (phoneNumber === list.receiver ||
                            phoneNumber === list.sender) &&
                          stopHideShow === false
                        ">
                    <p *ngIf="list.type === 'audio'">
                      <img src="assets/img/outgoing_phone_icon.png" width="15px" alt="phone-icon" />
                      Outgoing call
                    </p>
                  </ng-container>
                  <ng-container>
                    <p *ngIf="
                          list.type === 'audio' &&
                            phoneNumber !== list.receiver
                          ">
                      <img src="assets/img/outgoing_phone_icon.png" width="15px" alt="audio" />
                      Outgoing call
                    </p>
                    <p *ngIf="list.type === 'image'">
                      <img src="assets/img/image_icon.png" width="15px" alt="image_icon" />
                      Image Sent
                    </p>
                  </ng-container>

                  <small class="pull-right font-weight-light timer-text">
                    {{ list.timestamp | messageTime }}</small>
                </ng-container>
                <ng-container *ngIf="
                        list.direction === 'INBOUND' &&
                        list.sender === list.phone
                      ">
                  <p class="capitalize wraped-text ml-2" *ngIf="list.type !== 'audio' && list.type !== 'image'">
                    {{ list.message | slice : 0 : 56 }}
                  </p>
                  <p *ngIf="list.type === 'image'">
                    <img src="assets/img/image_icon.png" width="15px" alt="image_icon" />
                    Image Received
                  </p>
                  <p *ngIf="list.type === 'audio'">
                    <img src="assets/img/incoming_phone_icon.png" width="15px" alt="incoming_phone_icon" />
                    Incoming Call
                  </p>
                  <small class="pull-right font-weight-light timer-text">
                    {{ list.timestamp | messageTime }}</small>
                </ng-container>
                <ng-container *ngIf="
                        list.direction === 'INBOUND' &&
                        list.conversationId === list.phone
                      ">
                  <p class="capitalize wraped-text ml-2">
                    {{ list.message | slice : 0 : 56 }}
                  </p>
                  <small class="pull-right font-weight-light timer-text">
                    {{ list.timestamp | messageTime }}</small>
                </ng-container>
                <ng-container *ngIf="
                        list.direction === 'OUTBOUND' &&
                        list.conversationId === list.phone
                      ">
                  <p class="capitalize wraped-text ml-2">
                    {{ list.message | slice : 0 : 56 }}
                  </p>
                  <small class="pull-right font-weight-light timer-text">
                    {{ list.timestamp | messageTime }}</small>
                </ng-container>
              </div>
            </div>
          </div>
          <h6 class="text-primary pointer text-center" *ngIf="practitionerCount >= 10"><small
              (click)="loadOldPractitioners()" class="load-more-text">Load More</small></h6>
        </ng-container>
        <ng-container *ngIf="mobileChat">
          <div class="bg-profile">
            <div class="text-center">
              <button class="btn btn-arrow my-2 back-btn" (click)="backToList()">
                <img src="./assets/img/left arrow-min.png" class="icon-img" alt="arrow" />
              </button>

              <label class="mb-0 mt-1 title-name text-center font-weight-bold"
                *ngIf="selectedChat?.operator == 'TWILIO'">{{ selectedChat?.toUsername | titlecase }}</label>
              <label class="mb-0 mt-1 title-name text-center font-weight-bold"
                *ngIf="selectedChat?.operator != 'TWILIO'">{{ selectedChat?.fromUsername | titlecase }}</label>
            </div>
            <p class="mb-0 small mt-1 text-center ml-1" *ngIf="selectedChat?.operator == 'TWILIO'">
              {{ questionnaire?.mail ? questionnaire?.mail : "-" }}
            </p>
          </div>
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
              <a ngbNavLink>Message</a>
              <ng-template ngbNavContent>
                <div class="">
                  <app-chat-box [selectedChat]="selectedChat" [reloadChat]="reloadChat" [practionerId]="practionerId"
                    [getSelectedChat]="getSelectedChat" [isChatLoader]="isChatLoader" [error]="error"
                    [sending]="sending" [questionnaire]="questionnaire" [stopHideShow]="stopHideShow" [device]="device"
                    [practitioner]="practitioner"></app-chat-box>
                  <div id="tabs" #tabs class="tabbs">
                    <ul class="nav nav-pills">
                      <li class="nav-item" *ngIf="selectedChat.patientNumber">
                        <a class="nav-link" href="#sms" [ngClass]="{
                            active:
                              activetab === 'search' || activetab === 'result'
                          }" (click)="search('search', $event)" data-toggle="tab">
                          {{
                          (selectedChat?.operator == "TWILIO" || selectedChat?.operator == "MAILGUN")
                          ? "SMS"
                          : "Message"
                          }}</a>
                      </li>
                      <li class="nav-item"
                        *ngIf="(selectedChat?.operator == 'TWILIO' || selectedChat?.operator == 'MAILGUN') && selectedChat?.patientMail">
                        <a class="nav-link" href="#mail" [ngClass]="{ active: activetab === 'styles' }"
                          data-toggle="tab" (click)="styles('styles', $event)">Email</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane" id="sms" [ngClass]="{ active: activetab === 'search' }">
                        <div class="send-message m-2" [ngClass]="{
                            'border-danger': !textMessage && emptyMessage
                          }">
                          <textarea [(ngModel)]="textMessage" rows="3" (keydown.enter)="onKeydown($event)"
                            (keyup.enter)="
                              sendMessage(selectedChat, textMessage)
                            " name="textMessage " type="text " maxlength="1600 "
                            placeholder="Type your message"></textarea>
                          <button (click)="sendMessage(selectedChat, textMessage)">
                            <img src="./assets/img/send-min.png " class="icon-img" alt="mail " />
                          </button>
                        </div>
                      </div>
                      <div class="tab-pane" id="mail" [ngClass]="{ active: activetab === 'styles' }"
                        *ngIf="selectedChat.operator == 'TWILIO'">
                        <div class="small error p-2 mt-2 my-4 alert-danger unverify text-center bounce" *ngIf="dmErr">
                          Email service has not been set up yet. <br />Go
                          to<span (click)="navPage()">
                            <img src="./assets/img/emails.png" alt="Integrations" height="20px" width="20px"
                              class="mt-1" />
                            email settings
                          </span>
                          and add your domain to start sending emails.
                        </div>
                        <ng-container *ngIf="!dmErr">
                          <div class="border m-2 py-1 px-2 border-rounded">
                            <div class="row pt-1" style="height: auto">
                              <div class="col-md-6 form-group mb-0">
                                <label class="mb-0"><small>From :</small></label>
                                <input type="text" placeholder="{{ domainUser }}@{{
                                    domainName
                                  }}" disabled class="small form-control" />
                              </div>
                              <div class="col-md-6 form-group mb-0">
                                <label class="mb-0"><small>To :</small></label>
                                <input type="text" placeholder="{{ selectedChat?.patientMail }}" disabled
                                  class="small form-control" />
                              </div>
                            </div>
                            <form [formGroup]="sendEmail">
                              <div class="form-group input-adj my-2">
                                <input type="text" class="small form-control" formControlName="subject"
                                  placeholder="Subject" />
                              </div>
                              <div class="send-message" [ngClass]="{
                                  'border-danger': !textMessage && emptyMessage
                                }">
                                <textarea type="text " maxlength="1600 " formControlName="message" rows="3"
                                  placeholder="Type your mail"></textarea>
                              </div>
                            </form>
                            <div class="text-right">
                              <button (click)="sendMail(selectedChat?.patientMail)" class="btn btn-blue m-2">
                                <em class="bi bi-send mr-2"></em> Send
                              </button>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Activity</a>
              <ng-template ngbNavContent>
                <app-activity-section [selectedChat]="selectedChat" [irineChatRev]="irineChatRev"
                  [fbLeadForm]="fbLeadForm"></app-activity-section>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<modal #newMessageModal [modalConfig]="newMessageDialog">
  <h6 class="text-left font-weight-bold">
    New message
    <small (click)="closeDialog()" class="pull-right font-weight-bold pointer">x</small>
  </h6>
  <hr />
  <form [formGroup]="newMessageForm" class="text-left">
    <div class="form-group">
      <label>Patient Name</label>
      <select class="form-control" formControlName="patientName" (change)="getCustomerPhone($event.target.value)">
        <option value="" selected disabled>Select patient name</option>
        <option *ngFor="let patient of newMsgArr" [value]="patient.id">
          {{ patient.patientName }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Patient Phone</label>
      <input type="text" class="form-control" formControlName="patientPhone" />
    </div>
    <div class="form-group">
      <label>Enter a message</label>
      <textarea class="form-control" formControlName="message" name="message" maxlength="1600"></textarea>
    </div>
  </form>
  <p class="small" [ngClass]="{ 'text-danger': error, 'text-success': !error }" *ngIf="showSuccessText">
    {{ successText }}
  </p>
  <div class="mt-5 text-right">
    <button (click)="sendNewMessage('TWILIO')" class="btn btn-success ml-2 small" [disabled]="!newMessageForm.valid">
      Send message
    </button>
  </div>
</modal>
<modal #conversationDialog [modalConfig]="newMessageDialog">
  <h5 class="mt-4 text-center small">
    Phone number not associate with bot. Please contact your administrator.
  </h5>
  <div class="mt-4 text-center">
    <button (click)="errorDialog()" class="btn btn-warning small">Ok</button>
  </div>
</modal>
<modal #tokenExpiredDialog [modalConfig]="newMessageDialog">
  <h5 class="mt-4 text-center small">Your session has expired.</h5>
  <div class="mt-4 text-center">
    <button (click)="clearSesstionDialog()" class="btn btn-danger small">
      Ok
    </button>
  </div>
</modal>

<modal #quickFilter [modalConfig]="quickFilterConfig">
  <h6 class="text-left font-weight-bold">
    Quick Filter
    <small (click)="cleaerFilter()" class="pull-right font-weight-bold pointer text-muted">X Clear Filter</small>
  </h6>
  <hr />
  <form [formGroup]="filtersForm">
    <div class="text-left quick-filters">
      <h6>Last Message Direction</h6>
      <ul>
        <li *ngFor="let direction of messageDir"
            [ngClass]="{'active': selectedDirection == direction.value}"
            (click)="selectFilter('direction',direction.value)">
          {{ direction.type }}
        </li>
      </ul>
    </div>
    <div class="text-left quick-filters">
      <h6>Last Message Channel</h6>
      <ul>
        <li *ngFor="let channel of messageChannel"
            [ngClass]="{'active': selectedChannel == channel.value}"
            (click)="selectFilter('channel',channel.value)">
          {{ channel.type }}
        </li>
      </ul>
    </div>
  </form>
  
  
  <div class="mt-4 text-right">
    <button (click)="closeFilterDialog()" class="btn btn-secondary mx-2">Cancel</button>
    <button (click)="selectedFilterItems()" class="btn btn-primary mx-2" >Apply</button>
  </div>
</modal>
