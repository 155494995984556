// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  practitionerUrl: 'https://api.dev.hipokratiz.com/practitioner/v1/',
  baseUrl: 'https://api.dev.hipokratiz.com/',
  applicationID : '1d4bf199-2ca4-4925-8c8c-6144f46c7461',
  faceBookAppID : '1220959305099367',
  wss: 'wss://api.dev.hipokratiz.com/conversation-bff',
  domainValue : '3.15.124.155'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
