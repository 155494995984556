import { Injectable } from '@angular/core';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class FunnelsService {

    constructor(public http: HttpService) { }

    getAllFunnels() {
        return this.http.get(environment.baseUrl + 'webbuilder/v1/funnel/?practitionerid=' + localStorage.getItem('practitionerID'), true);
    }
    createFnnel(req: any){
        return this.http.post(environment.baseUrl + 'webbuilder/v1/funnel', req, true);
    }
    createDomainName(req: any, id: string){
        return this.http.post(environment.baseUrl + `webbuilder/v1/funnel/${id}/domain`, req, true);
    }
    deleteFnnel(req: any){
        return this.http.del(environment.baseUrl + `webbuilder/v1/funnel/${req.id}`, true);
    }
}
