<ng-container *ngFor="let alert of alerts ; let i =index">
    <div [ngClass]="{'alert-popup': alerts.length >0}" *ngIf="i == 0">
        <div class="alert-bg">
            <div class="{{cssClass(alert)}}">
                <ng-container *ngIf="alert.message != 401">
                    <p [innerHTML]="alert.message" class="m-5 "></p>
                    <button class="btn btn-secondary mb-2 small-text px-4" (click)="removeAlert(alert)">Ok</button>
                </ng-container>
                <ng-container *ngIf="alert.message == 401">
                    <p class="m-5 ">Your session has expired.</p>
                    <button class="btn btn-secondary mb-2 small-text px-4" (click)="logout()">Ok</button>
                </ng-container>
            </div>

        </div>
    </div>
</ng-container>