<div *ngIf="selectedChat">
  <div class="card mt-3">
    <div class="irine-chat d-none d-md-block">
      <h5 class="text-center bold">Activity</h5>
    </div>
    <ng-container *ngIf="selectedChat.operator === 'TWILIO' || selectedChat.operator === 'MAILGUN' ">
      <div id="tabs" #tabs>
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link active" href="#irineTab" [ngClass]="{ active: activeTab === 'irine' }"
              (click)="openTab('irine', $event)" data-toggle="tab">Irine</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#fbLeadTab" [ngClass]="{ active: activeTab === 'fbLead' }" data-toggle="tab"
              (click)="openTab('fbLead', $event)">Form Submissions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#notesTab" [ngClass]="{ active: activeTab === 'notes' }" data-toggle="tab"
              (click)="openNotesTab('notes', $event)">Notes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#taskTab" [ngClass]="{ active: activeTab === 'tasks' }" data-toggle="tab"
              (click)="openTaskTab('tasks', $event)">Tasks</a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane styles-tab" id="irineTab" [ngClass]="{ active: activeTab === 'irine' }">
            <udiv class="irine-chat-box" #inrineChatBox>
              <ng-container *ngFor="let irine of irineChatRev">
                <div class="bot-conversation mx-2" *ngIf="selectedChat">
                  <span class="line">
                    <h2>
                      <span>{{ irine.createdAt | date : "MMMM d, y" }}</span>
                    </h2>
                  </span>
                  <app-activity [irine]="irine"></app-activity>
                </div>
              </ng-container>
            </udiv>
          </div>
          <div class="tab-pane styles-tab" id="fbLeadTab" [ngClass]="{ active: activeTab === 'fbLead' }">
            <div class="fbLeadTab">
              <ng-container *ngFor="let data of fbLeadForm">
                <span class="line">
                  <h2>
                    <span>{{ data.createdAt | date : "MMMM d, y" }}</span>
                  </h2>
                </span>
                <h6 class="m-4 text-muted text-center text-capitalize">
                  {{ data.form_type }}
                </h6>
                <app-lead-activity [leadForm]="data"></app-lead-activity>
              </ng-container>
            </div>
          </div>


          <div class="tab-pane styles-tab" id="notesTab" [ngClass]="{ active: activeTab === 'notes' }">
            <ng-container *ngIf="activeTab === 'notes'">
              <div class="bot-conversation">
                <div class="text-right mt-4 mx-4 mb-0">
                  <button class="btn badge badge-primary p-2 font-weight-normal" (click)="openAddTask('notes')">+ Add
                    Note</button>
                </div>
                <span class="line">
                  <h2><span>Notes List</span></h2>
                </span>
                <div class="mx-3">
                  <ng-container *ngFor="let note of availableNotes">
                    <p class="mb-0 text-muted small pt-3">{{note.notes}}</p>
                    <div class="d-flex mb-0 small border-bottom">
                      <p class="mr-3" style="width: 35%;"><span class="text-muted">Date : </span> {{note.createdAt |
                        date}}</p>
                      <p class="mr-3"><span class="text-muted">By : </span> {{practitionerName}} </p>
                      <p class="ml-2">
                        <span class="mx-2 text-primary pointer" (click)="GetNotesById(note.id, note.notes)"><i
                            class="fa fa-edit"></i></span>
                        <span class="mx-2 text-danger pointer" (click)="deleteItem(note.id)"><i
                            class="fa fa-trash"></i></span>
                      </p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="availableNotes.length == 0">
                    <p class="text-center text-muted">No data</p>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>


          <div class="tab-pane styles-tab" id="taskTab" [ngClass]="{ active: activeTab === 'tasks' }">
            <ng-container *ngIf="activeTab === 'tasks'">
              <div class="bot-conversation">
                <div class="text-right mt-4 mx-4 mb-0">
                  <button class="btn badge badge-primary p-2 font-weight-normal" (click)="openAddTask('tasks')">+ Add
                    Task</button>
                </div>
                <span class="line">
                  <h2><span>Tasks List</span></h2>
                </span>
                <div class="mx-3">
                  <ng-container *ngFor="let task of availableTasks">
                    <p class="mb-0 text-muted small pt-3"
                      [ngClass]="{'text-decoration-line-through' : task.isComplete }"><input type="checkbox"
                        [checked]="task.isComplete" name="task" (change)="onCheckboxChange(task)" /> {{task.tasks}}</p>
                    <div class=" mb-0 small border-bottom">
                      <div class="d-flex">
                        <p class="mr-3 mb-0" style="width: 35%;"><span class="text-muted">Due : </span> {{task.dueDate |
                          date}}</p>
                        <p class="mr-3 mb-0"><span class="text-muted">By : </span> {{practitionerName}} </p>
                        <p class="ml-2 mb-0">
                          <span class="mx-2 text-primary pointer" (click)="GetTaskdById(task.id,task)"><i
                              class="fa fa-edit"></i></span>
                          <span class="mx-2 text-danger pointer" (click)="deleteItem(task.id)"><i
                              class="fa fa-trash"></i></span>
                        </p>
                      </div>
                      <small class="text-danger" *ngIf="isPastDue(task.dueDate)">The due date has passed!</small>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="availableTasks.length == 0">
                    <p class="text-center text-muted">No data</p>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<modal #notesModel [modalConfig]="notesModelConfig">
  <h6 class="text-left font-weight-bold text-capitalize"> Add Notes<small class="pull-right font-weight-bold pointer"
      (click)="closeModal('notes')">x</small></h6>
  <hr>
  <div class="container text-left my-5">
    <label class="text-capitalize">New Note<span class="text-danger">*</span></label>
    <textarea [(ngModel)]="description" name="description" class="form-control w-100" rows="4"></textarea>
    <p *ngIf="submitted" class="small text-danger my-2">This field is required</p>
    <hr>
    <div class="m-2 pull-right">
      <button class="btn btn-danger mx-2" (click)="closeModal('notes')">Cancel</button>
      <button *ngIf="!selectedItemId" class="btn btn-blue mx-2" (click)="addNotes()">Add Note</button>
      <button *ngIf="selectedItemId" class="btn btn-blue mx-2" (click)="updateNotes()">Update Note</button>
    </div>
  </div>
</modal>

<modal #taskModel [modalConfig]="taskModelConfig">
  <h6 class="text-left font-weight-bold text-capitalize"> Add Tasks<small class="pull-right font-weight-bold pointer"
      (click)="closeModal('task')">x</small></h6>
  <hr>
  <div class="container text-left my-5">
    <form [formGroup]="taskFrom">
      <label class="text-capitalize">New Task<span class="text-danger">*</span></label>
      <textarea formControlName="tasks" name="tasks" class="form-control w-100" rows="4"></textarea>
      <p *ngIf="submitted && taskFrom.controls.tasks.invalid" class="small text-danger my-2">This field is required</p>
      <label class="text-capitalize">Due Date <span class="text-danger">*</span></label>
      <input type="date" formControlName="dueDate" name="dueDate" class="form-control w-100" />
      <p *ngIf="submitted && taskFrom.controls.dueDate.invalid" class="small text-danger my-2">This field is required</p>

    </form>

    <hr>
    <div class="m-2 pull-right">
      <button class="btn btn-danger mx-2" (click)="closeModal('task')">Cancel</button>
      <button *ngIf="!selectedItemId" class="btn btn-blue mx-2" (click)="addTasks()">Add Task</button>
      <button *ngIf="selectedItemId" class="btn btn-blue mx-2" (click)="updateTasks()">Update Task</button>
    </div>
  </div>
</modal>