<nav *ngIf="menu.isVisible" class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion"
    aria-label="Main Navigation" [ngClass]="{'expanded': isExpanded}">
    <div class="sb-sidenav-menu" [ngClass]="{'sb-sidenav': true, 'expanded': isExpanded}" #sidenav>
        <div class="nav">
        <div class="title">
            <a class="navbar-brand small" (click)="getNavigated('/conversations')"><img src="assets/img/layout.png"
                    width="50px" height="50px" alt="Logo"> <span class="nav-title">Hipokratiz</span></a> 
        </div>
            <a (click)="expandSlider()"> <span class="slider-btn">
                <i class="bi" [ngClass]="isExpanded ? 'bi-caret-left': 'bi-caret-right'"></i>
            </span></a>
            <ul class="sb-sidenav-menu-heading">
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/conversations')" class="nav-link" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/conv-min.png" alt="conversations" height="20px" width="20px">
                        <span class="nav-title">Conversations</span> </a>
                </li>
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a routerLink="/profile" class="nav-link" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/profile-min.png" alt="profile" height="20px" width="20px">
                        <span class="nav-title">Profile</span></a>
                </li>


                <!-- <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/review')" class="nav-links" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/star.png" alt="settings" height="20px" width="20px">
                        <span class="nav-title">Reviews</span></a>
                </li> -->

                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/bot-configuration')" class="nav-link" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/setttings-min.png" alt="settings" height="20px" width="20px">
                        <span class="nav-title"> Bot Settings</span></a>
                </li>
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/integrations')" class="nav-link" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/integration.png" alt="Integrations" height="20px" width="20px">
                        <span class="nav-title"> Integrations</span></a>
                </li>
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/email')" class="nav-link" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/emails.png" alt="Integrations" height="20px" width="20px">
                        <span class="nav-title">Email Settings</span></a>
                </li>
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/recordings')" class="nav-link" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/REC-min.png" alt="settings" height="20px" width="20px">
                        <span class="nav-title"> Call History</span></a>
                </li>
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/system-form.png" alt="settings" height="20px" width="20px">
                        <span class="nav-title"><span class="align">FORM Builder</span><em
                                class="bi bi-caret-down-fill mx-3"></em></span></a>
                <div class="dropdown-menu adj1">
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/form-builder')" class="nav-links" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/system-form.png" alt="settings" height="20px" width="20px">
                        <span class="nav-title"> Form Builder</span></a>
                </li>
                <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                    <a (click)="getNavigated('/custom-form')" class="nav-links" routerLinkActive="active"
                        [ngClass]="{'stop-navigation': call == true}">
                        <img src="./assets/img/system-form.png" alt="settings" height="20px" width="20px">
                        <span class="nav-title">Custom Fields</span></a>
                </li>
        </div>
        </li>
        <ng-container *ngIf="fbPageId !== 'null'">
            <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
                <a (click)="getNavigated('/fb-forms')" class="nav-link" routerLinkActive="active"
                    [ngClass]="{'stop-navigation': call == true}">
                    <img src="./assets/img/fb-form.png" alt="settings" height="20px" width="20px">
                    <span class="nav-title">Facebook Form Fields Mapping</span></a>
            </li>
        </ng-container>
        <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
            <a (click)="getNavigated('/custom-values')" class="nav-link" routerLinkActive="active"
                [ngClass]="{'stop-navigation': call == true}">
                <img src="./assets/img/system-form.png" alt="settings" height="20px" width="20px">
                <span class="nav-title">Custom Values</span></a>
        </li>
        <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
            <a (click)="getNavigated('/work-flow')" class="nav-link" routerLinkActive="active"
                [ngClass]="{'stop-navigation': call == true}">
                <img src="./assets/img/wrkflow-min.png" alt="settings" height="20px" width="20px">
                <span class="nav-title">WorkFlow</span></a>
        </li>
        <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip" class="dp">
            <a class="dropdown-toggle" data-flip="false" data-toggle="dropdown" aria-expanded="false"
                routerLinkActive="active" [ngClass]="{'stop-navigation': call == true}">
                <img src="./assets/img/opportunity.png" alt="settings" height="20px" width="20px">
                <span class="nav-title"><span class="align">Opportunities</span><em
                        class="bi bi-caret-down-fill mx-2"></em></span></a>
            <div class="dropdown-menu adj1">
        <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
            <a (click)="getNavigated('/opportunities')" class="nav-links" routerLinkActive="active"
                [ngClass]="{'stop-navigation': call == true}">
                <img src="./assets/img/opportunity.png" alt="settings" height="20px" width="20px">
                <span class="nav-title">Opportunities</span></a>
        </li>
        <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
            <a (click)="getNavigated('/pipelines')" class="nav-links" routerLinkActive="active"
                [ngClass]="{'stop-navigation': call == true}">
                <img src="./assets/img/pipeline.png" alt="settings" height="20px" width="20px">
                <span class="nav-title">PipeLine</span></a>
        </li>
    </div>
    </li>
    <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
        <a (click)="getNavigated('/funnels')" class="nav-links" routerLinkActive="active"
            [ngClass]="{'stop-navigation': call == true}">
            <img src="./assets/img/pipeline.png" alt="settings" height="20px" width="20px">
            <span class="nav-title">Sites</span></a>
    </li>

    <li placement="bottom" ngbTooltip="{{call == true ? tootTipText : ''}}" tooltipClass="info-tooltip">
        <a (click)="getNavigated('/reputation')" class="nav-links" routerLinkActive="active"
            [ngClass]="{'stop-navigation': call == true}">
            <img src="./assets/img/star.png" alt="settings" height="20px" width="20px">
            <span class="nav-title">Reputation</span></a>
    </li>

   
    <div class="div">

    </div>
    </ul>
    <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-parent="#sidenavAccordion">
        <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages" aria-label="Main Navigation">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#pagesCollapseAuth"
                aria-expanded="false" aria-controls="pagesCollapseAuth">
                Authentication
                <div class="sb-sidenav-collapse-arrow"> <img src="./assets/img/down arrow-min.png" class="img-user"
                        alt="arrow" height="15px" width="15px"></div>
            </a>
            <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne"
                data-parent="#sidenavAccordionPages">
                <nav class="sb-sidenav-menu-nested nav" aria-label="Main Navigation">
                    <a class="nav-link" href="login.html">Login</a>
                    <a class="nav-link" href="register.html">Register</a>
                    <a class="nav-link" href="password.html">Forgot Password</a>
                </nav>
            </div>
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#pagesCollapseError"
                aria-expanded="false" aria-controls="pagesCollapseError">
                Error
                <div class="sb-sidenav-collapse-arrow"> <img src="./assets/img/down arrow-min.png" class="img-user"
                        alt="arrow" height="15px" width="15px"></div>
            </a>
        </nav>
    </div>
    </div>
    </div>

</nav>