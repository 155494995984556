import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/utility/http.service';

@Injectable({
  providedIn: 'root'
})
export class SmtpServiceService {

  constructor(private http: HttpService) { }

    getDetails(){
      return this.http.get(environment.baseUrl + 'tps-mailgun/v1/domain/' + localStorage.getItem('practitionerID'), true);
    }
    postmail(body){
      return this.http.post(environment.baseUrl + 'tps-mailgun/v1/domain', body, true);
    }
    postUser(body){
      return this.http.post(environment.baseUrl + 'tps-mailgun/v1/domain/senderusername', body, true);
    }
  }
