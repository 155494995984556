import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SidemenuService } from '../side-menu/sidemenu.service';
import { TopMenuService } from '../top-menu/top-menu.service';
import { AlertService } from '../utility/alert.service';
import { GlobalProvider } from '../utility/global.service';
import { LoginRequest, LoginResponse} from './login.model';
import { LoginService } from './login.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  authError: string;
  showError: boolean;


  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private loginService: LoginService,
    private alertService: AlertService,
    private global: GlobalProvider,
    public menu: SidemenuService,
    public nav: TopMenuService

  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,3}$')]],
      password: ['', Validators.required]
    });
    this.nav.hide();
    this.menu.hide();
    if (localStorage.getItem('practitionerID') !== null) {
      this.router.navigate(['/conversations']);
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    const loginReq: LoginRequest = {
      username: this.f.username.value,
      password: this.f.password.value,
      applicationId: environment.applicationID,
    };

    this.loginService.login(loginReq)
      .subscribe((data: LoginResponse) => {
        if (data.status === 'success') {
          const res = data.data.accessToken.response;
          const role = res.user.registrations?.length > 1 ? 'superAdmin' : '';
          const practitionerId = res.user.id;

          this.global.setLocalStorage('role', role);
          this.global.setLocalStorage('accessToken', res.token);
          if (role === 'superAdmin'){
            this.global.setLocalStorage('practitionerName', res.user?.username);
          }else{
            this.global.setLocalStorage('practitionerName', res.user?.firstName + ' ' + res.user?.lastName);
          }
          this.global.setLocalStorage('pageId', null);
          this.global.setLocalStorage('gmbId', null);
          this.global.setLocalStorage('locationID', null);
          if (role === 'superAdmin') {
            this.loginService.getPractitionerList().subscribe(details => {
              this.global.setLocalStorage('practitionerID', details.data[0].practitioner_Id);
              this.setLocationDetails(details, practitionerId);
              this.router.navigate(['/conversations']);

            });
          } else {
            this.global.setLocalStorage('practitionerID', practitionerId);
            this.loginService.getPractitionerDetails(practitionerId).subscribe(location => {
              this.global.setLocalStorage('status', 'ACTIVE');
              this.global.setLocalStorage('locationID', location?.data?.locations[0]?.id);
              this.global.setLocalStorage('locationDetails', JSON.stringify(location?.data?.locations[0]));
              this.setLocationAndPageDetails(location, res.user.id);
            });
          }
          const myRawToken = localStorage.getItem('accessToken');
          const expirationDate = helper.getTokenExpirationDate(myRawToken);
          const diffInMilliseconds = Math.abs(new Date(expirationDate).valueOf() - new Date().valueOf());
          localStorage.time = diffInMilliseconds;
        }
      },
        error => {
          this.showError = true;
          this.authError = error;
          this.loading = false;

        });
  }
  private setLocationDetails(details: any, practitionerId: any) {
    if (details.data[0]?.location_id) {
      const locationId = details.data[0]?.location_id;
      this.global.setLocalStorage('locationID', locationId);
      this.loginService.getGmbDetails().subscribe(gmb => {
        if (gmb.status === 'success') {
          gmb.data.agentId = gmb.data.locationLaunchStatus === 'launched' ? gmb.data.agentId : null;
          this.global.setLocalStorage('gmbId', gmb.data.agentId);
        }
      }, () => {
        console.log('Error fetching GMB details.');
      });
      this.loginService.getFbPageId(practitionerId, locationId).subscribe(pageId => {
        if (pageId.status === 'success') {
          this.global.setLocalStorage('pageId', pageId.data);
        }
      }, () => {
        console.log('Error fetching facebook page details.');
      });
    } else {
      this.router.navigate(['/profile']);
    }
  }
  private setLocationAndPageDetails(location: any, userId: string) {
    if (location.data.locations.length > 0) {
      this.loginService.getFbPageId(userId, location.data.locations[0].id).subscribe(pageId => {
        if (pageId.status === 'success') {
          this.global.setLocalStorage('pageId', pageId.data);
          this.getGmbDetailsAndNavigate();
        }
      }, error => {
        this.router.navigate(['/conversations']);
      });
    } else {
      this.global.locationsArr = location.data;
      this.global.setLocalStorage('status', 'ONBOARDED');
      this.router.navigate(['/profile']);
    }

}
private getGmbDetailsAndNavigate() {
  this.loginService.getGmbDetails().subscribe(gmb => {
    if (gmb.status === 'success') {
      gmb.data.agentId = gmb.data.locationLaunchStatus === 'launched' ? gmb.data.agentId : null;
      this.global.setLocalStorage('gmbId', gmb.data.agentId);
      this.router.navigate(['/conversations']);
    }
  }, error => {
    this.router.navigate(['/conversations']);
  });
}

}
