import { Injectable } from '@angular/core';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TopMenuService {

  visible: boolean;
  location: any = [];
  constructor(private http: HttpService) { this.visible = true; }
  hide() {
    this.visible = false;
  }
  show() {
    this.visible = true;
  }
  getPracticeList() {
    return this.http.get(environment.baseUrl + 'practitioner/v1/practitioner/' + localStorage.getItem('practitionerID'), true);
  }
  getPractitionerList() {
    return this.http.get(environment.baseUrl + 'practitioner/v1/practitioner/allDetails', true);
  }
  getGmbDetails() {
    return this.http.get(environment.baseUrl + 'tps-google/v1/accountDetails?practitionerId='
      + localStorage.getItem('practitionerID'), true);
  }
  getFbPageId(practId: string, locationId: any) {
    return this.http.get(environment.baseUrl + 'tps-meta/v1/connection/page?practitionerId=' + practId + '&locationId=' + locationId, true);
  }
}

