import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ChatService } from '../conversation/chat-service';
// declare const Twilio: any;
@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
})
export class ChatBoxComponent implements OnInit {
  showImgViewer: boolean;
  selectedUrl: any;
  sendMsgError: any;
  @Input() selectedChat: any;
  @Input() reloadChat: any;
  @Input() practionerId: any;
  @Input() getSelectedChat: any;
  @Input() isChatLoader: any;
  @Input() error: any;
  @Input() sending: any;
  @Input() stopHideShow: boolean;
  @Input() questionnaire: any;
  @Input() selectedLocationNumber: any;
  @Input() device: any;
  @Input() practitioner: any;
  @Input() lengthOfConversation: number;
  @Output() increasePageNumber = new EventEmitter<number>();
  @Output() getupdatedConversation = new EventEmitter<boolean>();

  selectedPatientName: any;
  phoneNumber: any;
  connection: any = null;
  callHideShow: any = true;
  isMuted = false;
  interval;
  time = 0;
  practitionerNumber: any;

  constructor(public chatService: ChatService, private el: ElementRef) { }

  ngOnInit(): void {
    this.practitionerNumber = JSON.parse(localStorage.getItem('locationDetails')).leadNotificationPhNo1;
    // console.log(profileNum.replace(/\s/g, ""))
    setTimeout(() => {
      this.setupHandlers(this.device);
    }, 1500);
  }
  display(value) {
    this.showImgViewer = true;
    this.selectedUrl = value;
  }
  setupHandlers(device) {

    device.on('ready', (_device) => {
      this.updateCallStatus('Ready to call');
    });
    device.on('error', (error) => {
      this.updateCallStatus('ERROR: ' + error.message);
    });
    device.on('connect', (connection) => {
      if ('phoneNumber' in connection.message) {
        this.updateCallStatus('In call with ' + connection.message.phoneNumber);
        this.callHideShow = false;
        this.stopHideShow = true;
        this.startTimer();
      } else {
        this.updateCallStatus('In call with support');
      }
    });
    device.on('disconnect', (connection) => {
      this.updateCallStatus('Call End');
      this.stopHideShow = false;
      this.callHideShow = true;
      this.pauseTimer();
    });

  }
  makeCall(phoneNumber, name) {
    this.selectedPatientName = name;
    this.phoneNumber = phoneNumber;
    if (this.connection == null) {
      console.log('connection is null. Initiating the call');
      this.callHideShow = false;
      this.stopHideShow = true;
      this.updateCallStatus('Calling ' + phoneNumber + '...');
      const params = { phoneNumber };

      const status = this.device.connect(params);
      status.on('ringing', () => {
        console.log('Ringing...');
      });
    } else {
      this.connection = null;
    }
  }
  updateCallStatus(status: string): void {
    console.log('Status -> ', status);
  }
  muteCall() {
    if (this.device.activeConnection().isMuted()) {
      this.device.activeConnection().mute(false);
    } else {
      this.device.activeConnection().mute(true);
    }
    this.isMuted = this.device.activeConnection().isMuted();
  }
  Stop() {
    this.isMuted = false;
    this.device.disconnectAll();
    if (this.device.activeConnection()){
    this.device.activeConnection().mute(false);
   }
    this.stopHideShow = false;
    this.callHideShow = true;
  }
  startTimer() {
    this.interval = setInterval(() => {
      // console.log(this.device.accept())
      if (this.time === 0) {
        this.time++;
      } else {
        console.log('Time was already incremented.');
        this.time++;
      }
    }, 1000);
  }
  pauseTimer() {
    clearInterval(this.interval);
    this.time = 0;
  }
  transform(value: number, args?: any): string {
    const hours: number = Math.floor(value / 60);
    const minutes: number = value - hours * 60;

    if (hours < 10 && minutes < 10) {
      return '0' + hours + ' : 0' + (value - hours * 60);
    }
    if (hours > 10 && minutes > 10) {
      return '0' + hours + ' : ' + (value - hours * 60);
    }
    if (hours > 10 && minutes < 10) {
      return hours + ' : 0' + (value - hours * 60);
    }
    if (minutes > 10) {
      return '0' + hours + ' : ' + (value - hours * 60);
    }
    if (minutes === 10) {
      return '0' + hours + ' : 10';
    }
  }
  closeImgViewer() {
    this.showImgViewer = false;
  }
  loadMoreMessages(){
    this.selectedChat.pageNumber++;
    this.increasePageNumber.emit(this.selectedChat);
  }
  markAsStarred(selectedChat){
    selectedChat.starred = !selectedChat.starred;
    const req ={
      id:selectedChat?.id,
      starred:selectedChat.starred
    }
    this.chatService.markAsStarred(req).subscribe(res=>{
      this.getupdatedConversation.emit(true);
    })
  }
  markAsUnRead(selectedChat){
    selectedChat.isRead = !selectedChat.isRead;
    const req ={
      id:selectedChat?.id,
      isRead:selectedChat.isRead
    }
    this.chatService.markAsUnRead(req).subscribe(res=>{
      this.getupdatedConversation.emit(true);    })
  }
}
