<div class="row">
    <div class="col-lg-12 ">
        <div class="d-md-flex logo">
            <div class=" text-left">
                <span>Hipokratiz</span>
            </div>

        </div>
        <p class="text-danger text-center text-error m-3"><small class="mr-50">{{authError}}</small></p>
        <div class="text-center m-2" *ngIf="authSuccess">
            <p class="alert alert-success"> You will receive an email with instructions about how to {{router.url == '/resendOnboardingEmail' ? 'set' : 'reset'}} your password in a few minutes.
                <a routerLink="/login" class="green-text">Click here to login.</a>
            </p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-8 col-10  p-md-5 p-4 m-auto  card shadow" *ngIf="!authSuccess">
                <h3 class="mb-4 font-weight-bold text-center"> {{router.url == '/resendOnboardingEmail' ? 'Enter email to get instructions' : 'Recover your password'}}
                </h3>
                <p class=" ml-2 text-center"> Enter your email and we will send you instructions to generate a new password.
                </p>
                <form class="login-form" [formGroup]="forgotPasswordForm">
                    <div class="group  mb-3">
                        <label class="small mb-1" for="inputEmailAddress">Email address</label>
                        <input type="text" class="form-control" formControlName="email" name="email" id="inputEmailAddress" />
                        <div *ngIf="submitted && forgotPassword.email.errors">
                            <div *ngIf="forgotPassword.email.errors.required" class="text-danger small">This field is required
                            </div>
                            <div *ngIf="forgotPassword.email.errors.pattern" class="text-danger small">Please enter a valid email address</div>
                        </div>
                    </div>
                    <div class="text-center  mb-3">
                        <button (click)="onSubmit()" class="btn btn-blue w-100">Send Instructions</button>
                    </div>
                    <div class="form-group text-center ">
                        <a class="link small" routerLink="/login"> Back to login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>