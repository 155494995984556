import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [SideMenuComponent],
  imports: [CommonModule,
   RouterModule],
  exports: [SideMenuComponent],
  bootstrap: [SideMenuComponent]
})
export class SideMenuModule { }
