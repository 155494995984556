import { Injectable } from '@angular/core';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LandingPageService {

    constructor(public http: HttpService) { }

    getTemplate(id: any) {
        return this.http.get(environment.baseUrl + 'webbuilder/v1/step/' + id, true);
    }
    getGoogleFonts() {
        return this.http.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyB4AP78fm59jgCh9w9ZFIuQgW9XcDj2iZo', true);
     }
     getFunnelsById(id: any){
        return this.http.get(environment.baseUrl + `webbuilder/v1/funnel/${id}`, true);
    }
}
