import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordRequest, PasswordSetRequest } from '../login/login.model';
import { GlobalProvider } from '../utility/global.service';
import { HttpService } from '../utility/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestPasswordService {

  constructor(public global: GlobalProvider,
              private httpSvc: HttpService) { }

  passwordSet(pwdSetReq: PasswordSetRequest): Observable<any> {
    return this.httpSvc.post(environment.baseUrl + 'practitioner/v1/practitioner/setpassword', pwdSetReq, false);
  }
  changePassword(req: ChangePasswordRequest){
    return this.httpSvc.post(environment.baseUrl + 'practitioner/v1/practitioner/password/changePassword', req, false);
  }
}
